// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../components/MainLayout.vue'
import Dashboard from '../views/DashboardView.vue'
import Carousel from '../views/CarouselView.vue'
import HospitalList from '../views/HospitalList.vue'
import ProductList from '../views/ProductList.vue'
import VipCustomization from '../views/VipCustomization.vue'
import VipTeam from '../views/VipTeam.vue'
import OrderList from '../views/OrderList.vue'
import UserList from '../views/UserList.vue'
import LotterySettings from '../views/LotterySettings.vue'
import UserSettings from '../views/UserSettings.vue'
import AccountManagement from '../views/AccountManagement.vue'
import LoginView from '../views/LoginView.vue'
import Assistance from '../views/MedicalAssistance.vue'
import Examination from '../views/PhysicalExamination.vue'
import AccompanyingService from '../views/AccompanyingService.vue'

const routes = [
    {
        path: '/',
        component: MainLayout,
        children: [
            { path: '/', name: 'Dashboard', component: Dashboard },
            { path: '/carousel', name: 'Carousel', component: Carousel },
            { path: '/hospital-list', name: 'HospitalList', component: HospitalList },
            { path: '/product-list', name: 'ProductList', component: ProductList },
            { path: '/vip-customization', name: 'VipCustomization', component: VipCustomization },
            { path: '/vip-team', name: 'VipTeam', component: VipTeam },
            { path: '/order-list', name: 'OrderList', component: OrderList },
            { path: '/user-list', name: 'UserList', component: UserList },
            { path: '/lottery-settings', name: 'LotterySettings', component: LotterySettings },
            { path: '/user-settings', name: 'UserSettings', component: UserSettings },
            { path: '/assistance', name: 'Assistance', component: Assistance },
            { path: '/examination', name: 'Examination', component: Examination },
            { path: '/account-management', name: 'AccountManagement', component: AccountManagement },
            { path: '/accompany-service', name: 'AccompanyingService', component: AccompanyingService }
        ]
    },
    { path: '/login', name: 'LoginView', component: LoginView }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
