<template>
  <router-view></router-view>
</template>

<script setup>

</script>

<style lang="less">
//@import '~ant-design-vue/dist/antd.less'; // 引入官方提供的 less 样式入口文件
#app{
  //width: 100%;
  //height: 100%;
  //background: @background-color-base;
}
</style>
