<script setup>

import {SmileOutlined, UploadOutlined} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import router from "@/router";
const {proxy} = getCurrentInstance()
// 轮播图操作
// -----------------------------------------------------------------------------------
// 轮播图上传图片
const carouselFileList = ref([]);
const uploading = ref(false);
const modelProduct = ref()
const handleCarouseRemove = file => {
  const index = carouselFileList.value.indexOf(file);
  const newFileList = carouselFileList.value.slice();
  newFileList.splice(index, 1);
  carouselFileList.value = newFileList;
};
const beforeCarouseUpload = file => {
  carouselFileList.value = [...(carouselFileList.value || []), file];
  handleCarouselChange();
  return false;
};
// 添加轮播图
const handleCarouselChange = () => {
  const formData = new FormData();
  carouselFileList.value.forEach(file => {
    formData.append('files[]', file);
  });
  uploading.value = true;
  let file = carouselFileList.value[0]  //file1是绑定的file对象
  let reader = new FileReader()
  let img = new Image()
  let aaa = ""
  // 读取图片
  reader.readAsDataURL(file)

  // 读取完毕后的操作
  reader.onloadend = async (e) => {
    img.src = e.target.result
    // 这里的e.target就是reader
    // console.log(reader.result)
    // reader.result就是图片的base64字符串
    aaa = reader.result
    const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

    try {
      const response = await proxy.$api.post(`/add_carousel`, {carousel: aaa});
      console.log(response)
      carouselFileList.value = [];
      uploading.value = false;
      message.success('上传成功！');
      await getData()
    } catch (error) {
      console.error(error);
    } finally {
      hideMessage();
    }
    //   axios
    //       .post('http://127.0.0.1/public/api/add_carousel', {carousel: aaa})
    //       .then(response => {
    //         console.log(response)
    //         carouselFileList.value = [];
    //         uploading.value = false;
    //         message.success('upload successfully.');
    //       })
    //       .catch(function (error) { // 请求失败处理
    //         console.log(error);
    //       });
    //   // console.log(imgBase64)
    // }
    // You can use any AJAX library you like

  };
}
// 删除轮播图
const onDeleteCarouse = async (id) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/delete_carousel', {id: id});
    message.success(response.data.state)
    await getData()
  } catch (error) {
    console.log(error);
  } finally {
    hideMessage();
  }
  // axios
  //     .post('http://127.0.0.1/public/api/delete_carousel', qs.stringify({id: id}))
  //     .then(response => (message.success(response.data.state)))
  //     .catch(function (error) { // 请求失败处理
  //       console.log(error);
  //     });
}
// 切换轮播图是否在微信小程序显示
const changeCarouselEnable = async (id, enable) => {
  // id -= 1
  // console.log(enable)
  // console.log(data.value[id])
  // enable = !enable
  // console.log(enable)
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/change_carousel_enable', {id: id, enable: enable});
    if (response["state"] === "success") {
      modelProduct.value = {}
      // modalVisibleProduct.value = false
    }
  } catch (error) {
    console.log(error);
  } finally {
    hideMessage();
  }
  // axios
  //     .post('http://127.0.0.1/public/api/change_carousel_enable', {id: id, enable: enable})
  //     .then(response => () => {
  //       if (response["state"] === "success") {
  //         modelProduct.value = {}
  //         // modalVisibleProduct.value = false
  //       }
  //     })
  //     .catch(function (error) { // 请求失败处理
  //       console.log(error);
  //     });
}
// 轮播图表单
const columns_carousel = [
  {
    name: 'id',
    title: '轮播图id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '轮播图url',
    dataIndex: 'url',
    key: 'url',
  },
  {
    title: '创建日期',
    dataIndex: 'create_date',
    key: 'create_date',
  },
  {
    title: '创建人',
    dataIndex: 'nickname',
    key: 'nickname',
  },
  {
    title: '启用',
    dataIndex: 'enable',
    key: 'enable',
  },
  {
    title: '操作',
    key: 'action',
  },
];
// -----------------------------------------------------------------------------------
const data = ref()
const loading = ref(false);
onMounted(async () => {
  loading.value = true; // 开始加载
  data.value = []
  await getData()

});
const getData = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_carousel');
    printResponse(response.data)
  } catch (error) {
    if (error.response.status === 401) {
      message.error("未授权")
    }
  } finally {
    hideMessage();
  }
};
// 改变data变量的值，显示表格数据
const printResponse = (responseData) => {
  if (responseData['status'] === 'error') {

    proxy.$cookies.set('loginCheck', 'false')
    // get_data()
    router.push({name: 'LoginView'})
  }
  data.value = responseData
  loading.value = false
}
</script>

<template>
  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>轮播图</a-breadcrumb-item>
      <!--                    <a-breadcrumb-item>List</a-breadcrumb-item>-->
      <!--                    <a-breadcrumb-item>App</a-breadcrumb-item>-->
    </a-breadcrumb>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
    >
      <div style="margin-bottom: 8px;display: flex">
        <a-upload :file-list="carouselFileList" :before-upload="beforeCarouseUpload"
                  @remove="handleCarouseRemove">
          <a-button>
            <upload-outlined></upload-outlined>
            上传轮播图
          </a-button>
        </a-upload>
        <!--                                <a-popconfirm-->
        <!--                                    title="确认删除？"-->
        <!--                                    @confirm="delectSelectTable"-->
        <!--                                    ok-text="确认"-->
        <!--                                    cancel-text="取消"-->
        <!--                                    style="display:block;margin-left: 20px"-->
        <!--                                >-->
        <!--                                    <a-button type="primary" danger style="display:block;margin-left: 20px">删除</a-button>-->
        <!--                                </a-popconfirm>-->

      </div>

      <a-table :row-selection="{ selectedRowKeys: tableSelectedRowKeys, onChange: onSelectChange }"
               :columns="columns_carousel" :data-source="data" :loading="loading"
               @change="handleTableChange">
        <template #headerCell="{ column }">
          <template v-if="column.key === 'id'">
                              <span>
                                <smile-outlined/>
                                轮播图id
                              </span>
          </template>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'url'">
            <a-image
                :width="100"
                :src="record.url"
            />
          </template>
          <template v-else-if="column.key === 'enable'">
            <a-switch v-model:checked='record.enable'
                      :checked-value="1"
                      :unchecked-value="0"
                      @click="changeCarouselEnable(record.id,record.enable)">
              <template #checkedChildren>
                <check-outlined/>
              </template>
              <template #unCheckedChildren>
                <close-outlined/>
              </template>
            </a-switch>
          </template>
          <template v-else-if="column.key === 'action'">
                                  <span>
<!--                                    <a @click="setModalVisible(record)">展示</a>-->
                                    <!--                                    <a @click="setModalVisible(record)">编辑</a>-->
                                    <!--                                    <a-divider type="vertical"/>-->
                                    <a-popconfirm
                                        v-if="data.length"
                                        title="确认删除？"
                                        @confirm="onDeleteCarouse(record.id)"
                                        ok-text="确认"
                                        cancel-text="取消"
                                    >
                                      <a>删除</a>
                                    </a-popconfirm>
                                  </span>
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </div>
</template>

<style scoped>

</style>
