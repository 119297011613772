<script setup>

import {ContactsOutlined, HomeOutlined, SmileOutlined, UserOutlined} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import router from "@/router";
const {proxy} = getCurrentInstance()
let user_info = ref({})
const changePersonInfo = ref(false)

// 个人
// -----------------------------------------------------------------------------------
// 修改个人信息
const submitPersonInfoChange = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const payload = {
      name: user_info.value.name,
      nickname: user_info.value.nickname
    };

    const response = await proxy.$api.post('/change_person_info', payload);
    message.success(response.data.state);
    changePersonInfo.value = false;
    await getData()
  } catch (error) {
    console.error(error);
    message.error('修改个人信息失败');
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
};


// -----------------------------------------------------------------------------------
const data = ref()
const loading = ref(false);
onMounted(async () => {
  loading.value = true; // 开始加载
  data.value = []
  await getData()

});
const getData = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_orders');
    printResponse(response.data)
  } catch (error) {
    if (error.response.status === 401) {
      message.error("未授权")
    }
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
};
// 改变data变量的值，显示表格数据
const printResponse = (responseData) => {
  if (responseData['status'] === 'error') {

    proxy.$cookies.set('loginCheck', 'false')
    // get_data()
    router.push({name: 'LoginView'})
  }
  data.value = responseData
  loading.value = false
}
</script>

<template>
  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>个人设置</a-breadcrumb-item>
      <!--                    <a-breadcrumb-item>List</a-breadcrumb-item>-->
      <!--                    <a-breadcrumb-item>App</a-breadcrumb-item>-->
    </a-breadcrumb>
    <a-layout-content>
      <a-row :gutter="[16]">
        <a-col :span="8">
          <a-card :bordered="false">
            <a-typography-title :level="2" style="text-align: center">{{ user_info.nickname }}
            </a-typography-title>
            <a-space align="baseline">
              <HomeOutlined/>
              <span>山东-临沂</span>
            </a-space>
            <a-divider/>
            <a-typography-text>功能</a-typography-text>
            <a-space align="baseline">
              <a-modal
                  v-model:open="changePersonInfo"
                  title="修改信息"
                  centered
                  :footer="null"
              >
                <div class="steps-content">
                  <a-form :model="user_info"
                          :label-col="{style: { width: '150px' }}"
                          :wrapper-col="{ span: 14 }">

                    <a-form-item label="登录账号">
                      <a-input v-model:value="user_info.name"
                               placeholder="登录账号">
                        <template #prefix>
                          <user-outlined/>
                        </template>
                        <template #suffix>
                          <a-tooltip title="Extra information">
                            <info-circle-outlined
                                style="color: rgba(0, 0, 0, 0.45)"/>
                          </a-tooltip>
                        </template>
                      </a-input>
                    </a-form-item>
                    <a-form-item label="昵称">
                      <a-input v-model:value="user_info.nickname" placeholder="账户昵称">
                        <template #prefix>
                          <ContactsOutlined/>
                        </template>
                        <template #suffix>
                          <a-tooltip title="Extra information">
                            <info-circle-outlined
                                style="color: rgba(0, 0, 0, 0.45)"/>
                          </a-tooltip>
                        </template>
                      </a-input>
                    </a-form-item>
                    <!--                                                        <a-form-item label="隶属单位">-->
                    <!--                                                            <a-input v-model:value="user_info.unit">-->
                    <!--                                                                <template #prefix>-->
                    <!--                                                                    <HomeOutlined/>-->
                    <!--                                                                </template>-->
                    <!--                                                                <template #suffix>-->
                    <!--                                                                    <a-tooltip title="Extra information">-->
                    <!--                                                                        <info-circle-outlined-->
                    <!--                                                                            style="color: rgba(0, 0, 0, 0.45)"/>-->
                    <!--                                                                    </a-tooltip>-->
                    <!--                                                                </template>-->
                    <!--                                                            </a-input>-->
                    <!--                                                        </a-form-item>-->
                  </a-form>
                </div>
                <div class="steps-action">
                  <a-button
                      type="primary"
                      @click="submitPersonInfoChange"
                  >
                    提交
                  </a-button>
                </div>
              </a-modal>
              <a-button type="link" @click="changePersonInfo=!changePersonInfo">修改信息
              </a-button>
              <a-modal
                  v-model:open="changePassword"
                  title="修改密码"
                  centered
                  :footer="null"
              >
                <div class="steps-content">
                  <a-form :model="passwordInfo"
                          :label-col="{style: { width: '150px' }}"
                          :wrapper-col="{ span: 14 }"
                  >

                    <a-form-item label="旧密码">
                      <a-input-password v-model:value="passwordInfo.old_password"
                                        placeholder="请输入旧密码">
                      </a-input-password>
                    </a-form-item>
                    <a-form-item label="新密码">
                      <a-input-password v-model:value="passwordInfo.new_password"
                                        placeholder="请输入新密码">
                      </a-input-password>
                    </a-form-item>
                    <a-form-item label="新密码">
                      <a-input-password v-model:value="passwordInfo.re_new_password"
                                        placeholder="请重复输入新密码">
                      </a-input-password>
                    </a-form-item>
                  </a-form>
                </div>
                <div class="steps-action">
                  <a-button
                      type="primary"
                      @click="submitPasswordChange"
                  >
                    提交
                  </a-button>
                </div>
              </a-modal>
              <a-button type="link" @click="changePassword=!changePassword">更换密码
              </a-button>
            </a-space>
          </a-card>
        </a-col>
        <a-col :span="16">
          <a-card
              :bordered="false"
              style="width: 100%"
              :tab-list="tabListNoTitle"
              :active-tab-key="noTitleKey"
              @tabChange="key => onTabChange(key, 'noTitleKey')"
          >
            <p v-if="noTitleKey === '订单'">
              <a-table
                  :row-selection="{ selectedRowKeys: tableSelectedRowKeys, onChange: onSelectChange }"
                  :columns="columns_orders" :data-source="data" :loading="loading"
                  @change="handleTableChange">
                <template #headerCell="{ column }">
                  <template v-if="column.key === 'id'">
                                                  <span>
                                                    <smile-outlined/>
                                                    订单id
                                                  </span>
                  </template>
                </template>

                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'url'">
                    <a>
                      {{ record.url }}
                    </a>
                  </template>
                  <template v-if="column.key === 'order_status'">
                    <a-badge v-if="record.order_status==='未付款'" color="#f50"
                             :text="record.order_status"/>
                    <a-badge v-if="record.order_status==='已付款'" color="#2db7f5"
                             :text="record.order_status"/>
                    <a-badge v-if="record.order_status==='已发货'" color="#yellow"
                             :text="record.order_status"/>
                    <a-badge v-if="record.order_status==='已签收'" color="#108ee9"
                             :text="record.order_status"/>
                    <a-badge v-if="record.order_status==='已确认'" color="#87d068"
                             :text="record.order_status"/>
                  </template>
                  <template v-else-if="column.key === 'action'">
                                  <span>
                                    <a @click="changeOrderData(record)">编辑</a>
                                    <a-divider type="vertical"/>
                                    <a-popconfirm
                                        v-if="data.length"
                                        title="确认删除？"
                                        @confirm="onOrderDelete(record.id)"
                                        ok-text="确认"
                                        cancel-text="取消"
                                    >
                                      <a>删除</a>
                                    </a-popconfirm>
                                  </span>
                  </template>
                </template>
              </a-table>


            </p>
            <!--                                <p v-else-if="noTitleKey === 'app'">app content</p>-->
            <!--                                <p v-else>project content</p>-->
          </a-card>
        </a-col>
      </a-row>
    </a-layout-content>
  </div>
</template>

<style scoped>

</style>
