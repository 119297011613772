// src/api.js
import axios from 'axios';

const api = axios.create({
    baseURL: 'https://jikangpeizhen.com/api/public/api', // 设置基础URL
    // baseURL: 'http://127.0.0.1/public/api', // 设置基础URL
    timeout: 10000, // 设置请求超时时间
    headers: {
        'Content-Type': 'application/json',
    },
});

// 请求拦截器
api.interceptors.request.use(
    config => {
        // 可以在这里添加token等信息
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

export default api;
