import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import router from './router/index'
import VueCookies from 'vue3-cookies'
import store from './store.ts'
import api from './api';
// import 'ant-design-vue/dist/antd.css';
import VueLazyload from 'vue-lazyload'

// import axios from "axios";
// 将axios实例挂载到Vue实例上

const app = createApp(App);  // 创建App
app.config.globalProperties.$api = api
app.use(VueLazyload)
app.use(router)
app.use(store)
app.use(VueCookies)
app.use(Antd).mount('#app');