<script setup>

import {SmileOutlined, UploadOutlined} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
import {computed, onMounted, ref} from "vue";
import {getCurrentInstance} from "vue";
import qs from "qs";
import router from "@/router";
const {proxy} = getCurrentInstance()
// 产品页面
// -----------------------------------------------------------------------------------
// 添加新产品
const productData = ref({
  "name": "",
  "image": '',
  "price": "",
  "enable": "",
  "description": "",
  "product_location": "",
  "hospital": "",
  "type_1": "",
  "type_2":"",
  "precautions": "", // 添加“注意事项”
  "service_details": "", // 添加“服务详情”
  "stock": "",
  "sales": "",
});

const current = ref(0)
const modelProduct = ref()
const modalAction = ref("add")
const modalProduct = ref(false)
const productPic = ref("")
const carouselFileList = ref([]);
const productFileList = ref([]);
const uploading = ref(false);
// 产品表单
const columns_product = [
  {
    name: 'id',
    title: '产品id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '产品名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '产品图片',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: '产品描述',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: '创建日期',
    dataIndex: 'create_date',
    key: 'create_date',
  },
  {
    title: '产品价格',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: '产品状态',
    dataIndex: 'enable',
    key: 'enable',
  },
  // {
  //     title: '产品位置',
  //     dataIndex: 'product_location',
  //     key: 'product_location',
  // },
  {
    title: '产品医院',
    dataIndex: 'hospital',
    key: 'hospital',
  },
  {
    title: '大类别',
    dataIndex: 'type_1',
    key: 'type_1',
  },
  {
    title: '小类别',
    dataIndex: 'type_2',
    key: 'type_2',
  },
  {
    title: '注意事项',
    dataIndex: 'precautions',
    key: 'precautions',
  },
  {
    title: '服务详情',
    dataIndex: 'service_details',
    key: 'service_details',
  },
  {
    title: '操作',
    key: 'action',
  },
];
const yuanPrice=(yuan)=> {
  return yuan / 100;
}
// const fenPrice=(yuan)=> {
//   return yuan * 100;
// }
// 点击添加产品按钮
const clickAddProduct = () => {
  productData.value = []
  productData.value.price = 0
  productData.value.enable = 1
  modalAction.value = 'add'
  modalProduct.value = !modalProduct.value
}
// 添加、修改产品
const submitProductChange = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  if (modalAction.value === 'update') {
    productData.value['image'] = productPic.value;
    console.log(productData.value)
    try {
      // productData.value.hospital_id = productData.value.hospital;
      const response = await proxy.$api.post('/change_product', productData.value);
      if (response.data.state === 'success') {
        carouselFileList.value = [];
        modalProduct.value = false;
        message.success('修改成功');
        modalAction.value = 'add';
        data.value = [];
        await getProducts()
      }
      else {
        message.success(response.data.state)
      }
    } catch (error) {
      message.error('修改失败');
    } finally {
      hideMessage();  // 关闭“加载中...”的提示
    }
  } else {
    try {
      const response = await proxy.$api.post('/add_product', {
        image: productPic.value,
        name: productData.value['name'],
        price: productData.value['price'], // 元转换为分
        description: productData.value['description'],
        enable: productData.value['enable'],
        hospital_id: productData.value['hospital'],
        type_1: productData.value['type_1'],
        type_2: productData.value['type_2'],
        type_3: productData.value['type_3'],
        stock: productData.value['stock'],
        sales: productData.value['sales'],
        precautions: productData.value['precautions'], // 提交注意事项
        service_details: productData.value['service_details'], // 提交服务详情
      });
      if (response.data.state === 'success') {
        productFileList.value = [];
        modalProduct.value = false;
        message.success('添加成功');
        await getProducts();
      }
    } catch (error) {
      message.error('添加失败');
    } finally {
      hideMessage();  // 关闭“加载中...”的提示
    }
  }
};


// 删除产品
const onDeleteProduct = async (id) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/delete_product', qs.stringify({id: id}));
    message.success(response.data.state)
    await getProducts()
  } catch (error) {
    message.error('修改失败');
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
  // axios
  //     .post('http://127.0.0.1/public/api/delete_product', qs.stringify({id: id}))
  //     .then(response => (message.success(response.data.state)))
  //     .catch(function (error) { // 请求失败处理
  //       console.log(error);
  //     });
}
// 切换产品是否在微信小程序显示
const changeProductEnable = async (id, enable) => {
  // id -= 1
  // console.log(enable)
  // console.log(data.value[id])
  // enable = !enable
  // console.log(enable)
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/change_product_enable', {id: id, enable: enable});
    if (response["state"] === "success") {
      modelProduct.value = {}
      // modalVisibleProduct.value = false
    }
  } catch (error) {
    message.error('修改失败');
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
}
// 修改产品信息
const changeProductData = (data) => {
  console.log(data)
  productPic.value = data.image
  productData.value = data
  productData.value.original_type_2 = String(data.original_type_2)
  // orderData.value['order_date'] = dayjs(orderData.value['order_date'], 'YYYY-MM-DD HH:mm:ss')
  modalProduct.value = true
  modalAction.value = 'update'
}

// 修改type_2信息
const handleProductType2Change = (data) => {
  console.log(data)
  productData.value.original_type_2 = data
  console.log(productData.value)
}

const handleHospitalChange = (data) => {
  productData.value.hospital_id = data
}
// 获取产品vip类别
const handleProductVIPFocus = async () => {
  productOptions.value = []
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_'+productData.value.type_1+'_label');
    response.data.forEach(function (item) {
      productOptions.value.push({
        value: item.id,
        label: item.name,
      });
    });
  } catch (error) {
    message.error('查询失败');
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
  // axios
  //     .post('http://127.0.0.1/public/api/get_vip_label')
  //     .then(response => {
  //       response.data.forEach(function (item) {
  //         productOptions.value.push({
  //           value: item.id,
  //           label: item.name,
  //         });
  //       });
  //     })
  //     .catch(function (error) { // 请求失败处理
  //       console.log(error);
  //     });
}

// 上传商品图片
const handleProductRemove = file => {
  const index = productFileList.value.indexOf(file);
  const newFileList = productFileList.value.slice();
  newFileList.splice(index, 1);
  productFileList.value = newFileList;
};
const beforeProductUpload = file => {
  productFileList.value = [...(productFileList.value || []), file];
  uploadProductPic();
  return false;
};
const uploadProductPic = () => {
  const formData = new FormData();
  productFileList.value.forEach(file => {
    formData.append('files[]', file);
  });
  uploading.value = true;
  let file = productFileList.value[0]  //file1是绑定的file对象
  let reader = new FileReader()
  let img = new Image()
  let aaa = ""
  // 读取图片
  reader.readAsDataURL(file)

  // 读取完毕后的操作
  reader.onloadend = (e) => {
    img.src = e.target.result
    // 这里的e.target就是reader
    // console.log(reader.result)
    // reader.result就是图片的base64字符串

    aaa = reader.result
    productPic.value = aaa
  }

};
// 产品选择医院
const productOptions = ref([]);
const productVIPOptions = ref([]);
// const productStateOptions = ref([
//     {
//         value: '0',
//         label: '未启用',
//     },
//     {
//         value: '1',
//         label: '已启用',
//     },
// ]);

// 获取产品医院标签
const handleProductFocus = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  productOptions.value = []
  productVIPOptions.value = []
  try {
    const response = await proxy.$api.post('/get_hospital_label');
    response.data.forEach(function (item) {
      productVIPOptions.value.push({
        value: item.id,
        label: item.name,
      });
    });
  } catch (error) {
    message.error('查询失败');
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
  // axios
  //     .post('http://127.0.0.1/public/api/get_hospital_label')
  //     .then(response => {
  //       response.data.forEach(function (item) {
  //         productOptions.value.push({
  //           value: item.id,
  //           label: item.name,
  //         });
  //       });
  //     })
  //     .catch(function (error) { // 请求失败处理
  //       console.log(error);
  //     });
}
// -----------------------------------------------------------------------------------
const data = ref()
const loading = ref(false);
onMounted(async () => {
  loading.value = true; // 开始加载
  data.value = []
  await getProducts()

});
const getProducts = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_products');
    printResponse(response.data)
  } catch (error) {
    if (error.response.status === 401) {
      message.error("未授权")
    }
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
}
// 改变data变量的值，显示表格数据
const printResponse = (responseData) => {
  if (responseData['status'] === 'error') {

    proxy.$cookies.set('loginCheck', 'false')
    // get_data()
    router.push({name: 'LoginView'})
  }
  data.value = responseData
  loading.value = false
}
// 计算属性，用于将价格从分转换为元
const priceInYuan = computed({
  get() {
    return (productData.value.price / 100).toFixed(2); // 转换为元，保留两位小数
  },
  set(value) {
    // 当用户输入价格时，将其转换为分存储
    productData.value.price = Math.round(value * 100);
  }
});


</script>

<template>
  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>产品列表</a-breadcrumb-item>
    </a-breadcrumb>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
    >
      <div style="margin-bottom: 8px;display: flex">
        <a-modal
            v-model:open="modalProduct"
            title="增加产品"
            centered
            :footer="null"
        >
          <div class="steps-content">
            <a-form v-if="current === 0" :model="productData"
                    :label-col="{style: { width: '150px' }}" :wrapper-col="{ span: 14 }">
              <a-image
                  v-if="productPic!==''"
                  :width="100"
                  :src="productPic"
                  style="margin-bottom: 10px"
              />
              <a-upload
                  :file-list="productFileList" :before-upload="beforeProductUpload"
                  @remove="handleProductRemove"
                  name="file"
                  :max-count="1"
                  style="display:block;margin-bottom: 20px"
                  :show-upload-list="false"
              >
                <a-button>
                  <upload-outlined></upload-outlined>
                  产品图片
                </a-button>
              </a-upload>

              <a-form-item label="产品名称">
                <a-input v-model:value="productData.name"/>
              </a-form-item>
              <a-form-item label="产品价格">
                <a-input v-model:value="priceInYuan"/>
              </a-form-item>
              <a-form-item label="产品库存">
                <a-input v-model:value="productData.stock"/>
              </a-form-item>
              <a-form-item label="产品售出数量">
                <a-input v-model:value="productData.sales"/>
              </a-form-item>
              <a-form-item label="产品描述">
                <a-input v-model:value="productData.description"/>
              </a-form-item>


              <a-form-item label="产品状态">
                <a-select
                    ref="select"
                    v-model:value="productData.enable"
                    style="width: 100%"
                >
                  <a-select-option :value=0>未启用</a-select-option>
                  <a-select-option :value=1>已启用</a-select-option>
                </a-select>
              </a-form-item>
              <!--                                        <a-form-item label="产品位置">-->
              <!--                                            <a-input v-model:value="productData.product_location"/>-->
              <!--                                        </a-form-item>-->
              <a-form-item label="产品所属医院">
                <a-select
                    v-model:value="productData.hospital"
                    placeholder="选择一个医院"
                    style="width: 100%"
                    :options="productVIPOptions"
                    @focus="handleProductFocus"
                    @change="handleHospitalChange"
                ></a-select>
                <!--                                            <a-input v-model:value="productData.hospital"/>-->
              </a-form-item>
              <a-form-item label="大类别">
                <a-select
                    ref="select"
                    v-model:value="productData.type_1"
                    style="width: 100%"
                >
                  <a-select-option value='accompanying'>陪诊服务</a-select-option>
                  <a-select-option value='assistance'>医疗辅助</a-select-option>
                  <a-select-option value='examination'>体检服务</a-select-option>
                  <a-select-option value='vip'>VIP个人</a-select-option>
                  <a-select-option value='vip_team'>VIP团体</a-select-option>
              </a-select>
              </a-form-item>
              <a-form-item label="小类别">
                <a-select
                    v-model:value="productData.type_2"
                    placeholder="选择一个类别"
                    style="width: 100%"
                    :options="productOptions"
                    @focus="handleProductVIPFocus"
                    @change="handleProductType2Change"
                ></a-select>
                <!--                                            <a-input v-model:value="productData.hospital"/>-->
              </a-form-item>
              <a-form-item label="实物/服务">
                <a-select
                    ref="select"
                    v-model:value="productData.type_3"
                    style="width: 100%"
                >
                  <a-select-option value='physical'>实物</a-select-option>
                  <a-select-option value='service'>服务</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="注意事项">
                <a-textarea v-model:value="productData.precautions" />
              </a-form-item>
              <a-form-item label="服务详情">
                <a-textarea v-model:value="productData.service_details" />
              </a-form-item>
              <!--                                        <a-form-item label="创建时间">-->
              <!--                                            <a-input v-model:value="modalData.create_time" />-->
              <!--                                        </a-form-item>-->
            </a-form>
          </div>
          <div class="steps-action">
            <a-button
                type="primary"
                @click="submitProductChange"
            >
              提交
            </a-button>
          </div>
        </a-modal>
        <a-button class="editable-add-btn" @click="clickAddProduct">添加产品</a-button>

      </div>

      <a-table :row-selection="{ selectedRowKeys: tableSelectedRowKeys, onChange: onSelectChange }"
               :columns="columns_product" :data-source="data" :loading="loading"
               @change="handleTableChange">
        <template #headerCell="{ column }">
          <template v-if="column.key === 'id'">
                              <span>
                                <smile-outlined/>
                                产品id
                              </span>
          </template>
        </template>
        <template #bodyCell="{ column,record }">
          <template v-if="column.key === 'image'">
            <a-image
                :width="100"
                :src="record.image"
            />
          </template>
          <template v-if="column.key === 'type_1'">
            <a-tooltip :title="record.type_1">
              <a-text ellipsis :rows="1">
                <!-- 根据 vip_type 的值显示对应文本 -->
                {{
                  record.type_1 === 'accompanying' ? '陪诊服务' :
                      record.type_1 === 'assistance' ? '医疗辅助' :
                          record.type_1 === 'examination' ? '体检服务' :
                              record.type_1 === 'vip' ? 'VIP个人' :
                                  record.type_1 === 'vip_team' ? 'VIP团体' :
                                      '未知类型'
                }}
              </a-text>
            </a-tooltip>
          </template>
          <template v-if="column.key === 'price'">
            <a-tooltip>
                <!-- 根据 vip_type 的值显示对应文本 -->
                {{ yuanPrice(record.price) }}
            </a-tooltip>
          </template>
          <template v-else-if="column.key === 'enable'">
            <a-switch v-model:checked="record.enable"
                      :checked-value="1"
                      :unchecked-value="0"
                      @click="changeProductEnable(record.id,record.enable)">
              <template #checkedChildren>
                <check-outlined/>
              </template>
              <template #unCheckedChildren>
                <close-outlined/>
              </template>
            </a-switch>
          </template>
          <template v-if="column.key === 'action'">
                                  <span>
                                    <a @click="changeProductData(record)">编辑</a>
                                    <a-divider type="vertical"/>
                                    <a-popconfirm
                                        v-if="data.length"
                                        title="确认删除？"
                                        @confirm="onDeleteProduct(record.id)"
                                        ok-text="确认"
                                        cancel-text="取消"
                                    >
                                      <a>删除</a>
                                    </a-popconfirm>
                                  </span>
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </div>
</template>

<style scoped>

</style>
