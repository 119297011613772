<script setup>

// 首页画图 开始
// -----------------------------------------------------------------------------------
import VChart, {THEME_KEY} from 'vue-echarts';
// 引入echarts
import {use} from 'echarts/core'
import {BarChart, LineChart, PieChart} from 'echarts/charts'
import {GridComponent, LegendComponent, TooltipComponent} from 'echarts/components'
import {CanvasRenderer} from 'echarts/renderers'
import {provide, ref} from "vue";
// import {getCurrentInstance} from "vue";
// const {proxy} = getCurrentInstance()
use([GridComponent, LineChart, CanvasRenderer])
// 个人设置
const tabListNoTitle = [
  {
    key: '订单',
    tab: '订单',
  },
];
const key = ref('tab1');
const noTitleKey = ref('订单');
const onTabChange = (value, type) => {
  // console.log(value, type);
  if (type === 'key') {
    key.value = value;
  } else if (type === 'noTitleKey') {
    noTitleKey.value = value;
  }
}
// -----------------------------------------------------------------------------------

// 管理页面 通用变量
const hotColumns = [
  {
    title: '排名',
    dataIndex: 'rank',
  },
  {
    title: '商品名称',
    dataIndex: 'name',
  },
  {
    title: '点击量',
    dataIndex: 'click',
    sorter: {
      compare: (a, b) => a.click - b.click,
      multiple: 2,
    },
  },
  {
    title: '周涨幅',
    dataIndex: 'weeklygains',
    sorter: {
      compare: (a, b) => a.weeklygains - b.weeklygains,
      multiple: 1,
    },
  },
]
const hotData = [
  {
    key: '1',
    rank: 1,
    name: 'John Brown',
    click: 98,
    weeklygains: 609,
  },
  {
    key: '2',
    rank: 2,
    name: 'John Brown',
    click: 982,
    weeklygains: 630,
  },
  {
    key: '3',
    rank: 3,
    name: 'John Brown',
    click: 910,
    weeklygains: 610,
  },
  {
    key: '4',
    rank: 3,
    name: 'John Brown',
    click: 928,
    weeklygains: 601,
  },
]
provide(THEME_KEY, 'light');
const option_1 = ref({
  xAxis: {
    show: false,
    type: 'category',
    boundaryGap: false,
    // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    show: false,
    type: 'value'
  },
  grid: {
    top: '0%',
    left: '0%',
    right: '0%',
    bottom: '0%',
  },
  series: [
    {
      data: [820, 932, 901, 934, 1290, 1330, 1320],
      type: 'line',
      areaStyle: {}
    }
  ]
});

use([TooltipComponent, GridComponent, BarChart, CanvasRenderer])


const option_5 = ref(
    {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: '订单',
          type: 'bar',
          barWidth: '60%',
          data: [10, 52, 200, 334, 390, 330, 220, 11, 223, 1123, 123, 33]
        }
      ]
    });

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer])
const option_6 = ref(
    {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            {value: 1048, name: '轮椅'},
            {value: 735, name: '预约'},
            {value: 580, name: '药品'},
            {value: 484, name: '医用品'},
            {value: 300, name: '老年人'}
          ]
        }
      ]
    });
// 首页画图 结束
// -----------------------------------------------------------------------------------

</script>

<template>
  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>数据总览</a-breadcrumb-item>
      <!--                    <a-breadcrumb-item>List</a-breadcrumb-item>-->
      <!--                    <a-breadcrumb-item>App</a-breadcrumb-item>-->
    </a-breadcrumb>
    <a-layout-content>
      <a-flex gap="middle" vertical>
        <a-row :gutter="[16]">
          <a-col :span="6">
            <a-card :bordered="false">
              <a-typography-text type="secondary">访问量</a-typography-text>
              <div class="content">
                11111 元
              </div>
              <div>
                <v-chart style="  height: 80px;" class="chart" :option="option_1"
                         autoresize/>
              </div>
              <a-divider style="margin: 10px 0;"/>
              <a-typography-text>日销售额：111</a-typography-text>
            </a-card>
          </a-col>
          <a-col :span="6">
            <a-card :bordered="false">
              <a-typography-text type="secondary">访问量</a-typography-text>
              <div class="content">
                11111 元
              </div>
              <div>
                <v-chart style="  height: 80px;" class="chart" :option="option_1"
                         autoresize/>
              </div>
              <a-divider style="margin: 10px 0;"/>
              <a-typography-text>日销售额：111</a-typography-text>
            </a-card>
          </a-col>
          <a-col :span="6">
            <a-card :bordered="false">
              <a-typography-text type="secondary">访问量</a-typography-text>
              <div class="content">
                11111 元
              </div>
              <div>
                <v-chart style="  height: 80px;" class="chart" :option="option_1"
                         autoresize/>
              </div>
              <a-divider style="margin: 10px 0;"/>
              <a-typography-text>日销售额：111</a-typography-text>
            </a-card>
          </a-col>
          <a-col :span="6">
            <a-card :bordered="false">
              <a-typography-text type="secondary">访问量</a-typography-text>
              <div class="content">
                11111 元
              </div>
              <div>
                <v-chart style="  height: 80px;" class="chart" :option="option_1"
                         autoresize/>
              </div>
              <a-divider style="margin: 10px 0;"/>
              <a-typography-text>日销售额：111</a-typography-text>
            </a-card>
          </a-col>
        </a-row>
        <a-card
            :bordered="false"
            style="width: 100%"
            :tab-list="tabListNoTitle"
            :active-tab-key="noTitleKey"
            @tabChange="key => onTabChange(key, 'noTitleKey')"
        >
          <a-typography-text>销售趋势</a-typography-text>
          <v-chart style="  height: 380px;" class="chart" :option="option_5" autoresize/>
        </a-card>
        <a-row :gutter="[16]">
          <a-col :span="12">
            <a-card :bordered="false" title="线上热门搜索" style="height: 500px">
              <a-table :columns="hotColumns" :data-source="hotData" @change="onChange"/>

            </a-card>
          </a-col>
          <a-col :span="12">
            <a-card :bordered="false" title="销售额类别占比" style="height: 500px">
              <v-chart style="  height: 380px;" class="chart" :option="option_6" autoresize/>
            </a-card>
          </a-col>
        </a-row>
      </a-flex>


    </a-layout-content>
  </div>
</template>

<style scoped>

</style>