<script setup>

import {SmileOutlined, UploadOutlined} from "@ant-design/icons-vue";
import {onMounted, ref} from "vue";
import {message} from "ant-design-vue";
import {getCurrentInstance} from "vue";
import router from "@/router";
const {proxy} = getCurrentInstance()
// 抽奖
const modalLottery = ref(false);
const modalAction = ref("add");
const lotteryFileList = ref([]);
const lotteryPic = ref("")
const uploading = ref(false);
const modelProduct = ref()
const lotteryData = ref({
  'fileList': [],
  'imageUrl': '',
  'name': '',
  'probability': 0,
  'quantity': 1,
  'prize_amount':0,
  'product_id': 0,
  'prize_type': '',
  'valid_until': 0,
  'enable': true
});
const columns_lottery = [
  {key: 'name', title: '抽奖名称'},
  // {key: 'image', title: '抽奖图片'},
  {key: 'probability', title: '抽奖概率 (%)'},
  {key: 'quantity', title: '抽奖数量'},
  {key: 'enable', title: '是否启用'},
  {key: 'action', title: '操作'}
];


async function submitLotteryChange() {
  if (lotteryData.value.prize_type === 'physical') {
    lotteryData.value.prize_amount = lotteryData.value.product_id
  }
  const payload = {
    name: lotteryData.value.name,
    image: lotteryPic.value,
    probability: lotteryData.value.probability,
    quantity: lotteryData.value.quantity,
    prize_type: lotteryData.value.prize_type,
    prize_amount: lotteryData.value.prize_amount,
    valid_until: lotteryData.value.valid_until,
    enable: lotteryData.value.enable
  };
  if (modalAction.value === 'add') {
    const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

    try {

      const response = await proxy.$api.post('/add_lottery', payload);
      if (response.data.state === 'success') {
        lotteryFileList.value = [];
        modalLottery.value = false;
        message.success('添加成功');
        await getData()
      }
      message.success(response.data.state);
      modalLottery.value = false;
    } catch (error) {
      console.error('Error submitting lottery:', error);
      message.error('提交失败，请重试！');
    } finally {
      hideMessage();  // 关闭“加载中...”的提示
    }
  } else {
    const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

    try {
      payload.id = lotteryData.value.id;
      const response = await proxy.$api.post('/update_lottery', payload);
      if (response.data.state === '修改成功！') {
        lotteryFileList.value = [];
        modalLottery.value = false;
        message.success(response.data.state);
        await getData()
      }
      else  message.error('修改失败，请重试！');
    } catch (error) {
      message.error('修改失败，请重试！');
    }
    finally {
      hideMessage();  // 关闭“加载中...”的提示
    }
  }
}

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
async function deleteLottery(id) {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  const payload = {
    id: id,
  };

  const response = await proxy.$api.post('/delete_lottery', payload);
  if (response.data.state) {
    hideMessage();  // 关闭“加载中...”的提示
    message.success('删除成功！');

    await getData()
  } else {
    message.error(response.data.message || '删除失败，请重试！');
  }
}
const productList = ref([])
async function getProductList() {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_products');
    productList.value = response.data
  } catch (error) {
    if (error.response.status === 401) {
      message.error("未授权")
    }
  }
  finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
}

// 上传商品图片
const handleLotteryRemove = file => {
  const index = lotteryFileList.value.indexOf(file);
  const newFileList = lotteryFileList.value.slice();
  newFileList.splice(index, 1);
  lotteryFileList.value = newFileList;
};
const beforeLotteryUpload = file => {
  lotteryFileList.value = [...(lotteryFileList.value || []), file];
  uploadLotteryPic();
  return false;
};
const uploadLotteryPic = () => {
  const formData = new FormData();
  lotteryFileList.value.forEach(file => {
    formData.append('files[]', file);
  });
  uploading.value = true;
  let file = lotteryFileList.value[0]  //file1是绑定的file对象
  let reader = new FileReader()
  let img = new Image()
  let aaa = ""
  // 读取图片
  reader.readAsDataURL(file)

  // 读取完毕后的操作
  reader.onloadend = (e) => {
    img.src = e.target.result
    // 这里的e.target就是reader
    // console.log(reader.result)
    // reader.result就是图片的base64字符串

    aaa = reader.result
    lotteryPic.value = aaa
  }

};
// 点击添加产品按钮
const clickAddLottery = () => {
  lotteryData.value = []
  lotteryPic.value = ""
  modalAction.value = 'add'
  modalLottery.value = !modalLottery.value
  getProductList()
};
// 切换奖品是否在微信小程序显示
const changeLotteryEnable = async (id, enable) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/change_lottery_enable', {id: id, enable: enable});
    if (response["state"] === "success") {
      modelProduct.value = {}
      // modalVisibleProduct.value = false
    }
  } catch (error) {
    message.error('修改失败');
  }
  finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
}

// 修改抽奖信息
const changeLotteryData = (data) => {
  lotteryPic.value = data.image
  console.log(data)
  lotteryData.value = data
  // orderData.value['order_date'] = dayjs(orderData.value['order_date'], 'YYYY-MM-DD HH:mm:ss')
  modalLottery.value = true
  modalAction.value = 'update'

}
const data = ref()
const loading = ref(false);
onMounted(async () => {
  loading.value = true; // 开始加载
  data.value = []
  await getData()

});
const getData = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_lottery');
    printResponse(response.data)
  } catch (error) {
    if (error.response.status === 401) {
      message.error("未授权")
    }
  }
  finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
};
// 改变data变量的值，显示表格数据
const printResponse = (responseData) => {
  if (responseData['status'] === 'error') {

    proxy.$cookies.set('loginCheck', 'false')
    // get_data()
    router.push({name: 'LoginView'})
  }
  data.value = responseData
  loading.value = false
}
</script>

<template>
  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>抽奖设置</a-breadcrumb-item>
    </a-breadcrumb>
    <a-layout-content :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }">
      <div style="margin-bottom: 8px;display: flex">
        <a-modal v-model:open="modalLottery" title="抽奖" centered :footer="null">
          <a-form :model="lotteryData" :label-col="{style: { width: '150px' }}" :wrapper-col="{ span: 14 }">
            <a-form-item label="抽奖名称">
              <a-input v-model:value="lotteryData.name" placeholder="输入抽奖名称"/>
            </a-form-item>

            <a-form-item label="奖品状态">
              <a-select v-model:value="lotteryData.prize_type" style="width: 100%">
                <a-select-option value="physical">实体奖品</a-select-option>
                <a-select-option value="discount">抵扣奖品</a-select-option>
              </a-select>
            </a-form-item>

            <!-- 实体奖品选择 -->
            <a-form-item v-if="lotteryData.prize_type === 'physical'" label="选择产品">
              <a-select v-model:value="lotteryData.product_id" style="width: 100%">
                <a-select-option v-for="product in productList" :key="product.id" :value="product.id">
                  {{ product.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <!-- 抵扣金额输入 -->
            <a-form-item v-if="lotteryData.prize_type === 'discount'" label="抵扣金额 /元">
              <a-input-number v-model:value="lotteryData.prize_amount" :min="0" />
            </a-form-item>

            <a-form-item label="抽奖图片">
              <a-image v-if="lotteryPic !== ''" :width="100" :src="lotteryPic" style="margin-bottom: 10px"/>
              <a-upload :file-list="lotteryFileList" :before-upload="beforeLotteryUpload" @remove="handleLotteryRemove" :max-count="1" :show-upload-list="false">
                <a-button>
                  <upload-outlined/> 上传图片
                </a-button>
              </a-upload>
            </a-form-item>

            <a-form-item label="抽奖概率 /百分比">
              <a-input-number v-model:value="lotteryData.probability" :min="0" :max="100"/>
            </a-form-item>

            <a-form-item label="抽奖数量 /个">
              <a-input-number v-model:value="lotteryData.quantity" min="1"/>
            </a-form-item>
            <a-form-item label="有效期 /天">
              <a-input-number v-model:value="lotteryData.valid_until" min="1"/>
            </a-form-item>

            <a-form-item label="奖品状态">
              <a-select v-model:value="lotteryData.enable" style="width: 100%">
                <a-select-option :value="0">未启用</a-select-option>
                <a-select-option :value="1">已启用</a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
          <div class="steps-action">
            <a-button type="primary" @click="submitLotteryChange">提交</a-button>
          </div>
        </a-modal>
        <a-button class="editable-add-btn" @click="clickAddLottery">添加抽奖</a-button>
      </div>
      <a-table :row-selection="{ selectedRowKeys: tableSelectedRowKeys, onChange: onSelectChange }"
               :columns="columns_lottery" :data-source="data" :loading="loading"
               @change="handleTableChange">
        <template #headerCell="{ column }">
          <template v-if="column.key === 'id'">
                    <span>
                        <smile-outlined/>
                        抽奖ID
                    </span>
          </template>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'name'">
            {{ record.name }}
          </template>
          <template v-if="column.key === 'image'">
            <a-image :src="record.image" :width="100"/>
          </template>
          <template v-if="column.key === 'probability'">
            {{ record.probability }}%
          </template>
          <template v-if="column.key === 'quantity'">
            {{ record.quantity }}
          </template>
          <template v-if="column.key === 'type'">
            <a-switch v-model:checked="record.type"
                      :checked-value="1"
                      :unchecked-value="0"
                      @click="changeLotteryEnable(record.id,record.type)">
              <template #checkedChildren>
                <check-outlined/>
              </template>
              <template #unCheckedChildren>
                <close-outlined/>
              </template>
            </a-switch>
          </template>
          <template v-if="column.key === 'enable'">
            <a-switch v-model:checked="record.enable"
                      :checked-value="1"
                      :unchecked-value="0"
                      @click="changeLotteryEnable(record.id,record.enable)">
              <template #checkedChildren>
                <check-outlined/>
              </template>
              <template #unCheckedChildren>
                <close-outlined/>
              </template>
            </a-switch>
          </template>
        <template v-if="column.key === 'action'">
                    <span>
                        <a @click="changeLotteryData(record)">编辑</a>
                        <a-divider type="vertical"/>
                        <a-popconfirm title="确认删除？" @confirm="deleteLottery(record.id)" ok-text="确认"
                                      cancel-text="取消">
                            <a>删除</a>
                        </a-popconfirm>
                    </span>
        </template>
        </template>
      </a-table>
    </a-layout-content>
  </div>
</template>

<style scoped>

</style>
