<script setup>

import {SmileOutlined} from "@ant-design/icons-vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import {message} from "ant-design-vue";
import router from "@/router";
const {proxy} = getCurrentInstance()
// VIP
// -----------------------------------------------------------------------------------
// VIP
const modalVIP = ref(false)
const modalAction = ref("add")
const modelProduct = ref()

const VIPData = ref({'id': '', 'name': '', 'enable': ''})
// 修改VIP信息
const changeVIPData = (data) => {
  console.log(data)
  VIPData.value = data
  modalVIP.value = true
  modalAction.value = 'update'
}
// 点击添加VIP按钮
const clickAddVIP = () => {
  VIPData.value = []
  modalAction.value = 'add'
  modalVIP.value = !modalVIP.value
}
// 添加vip
const submitVIPChange = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    let response;
    if (modalAction.value === 'update') {
      response = await proxy.$api.post('/change_vip_team', VIPData.value);
    } else {
      const payload = {
        name: VIPData.value['name'],
        enable: VIPData.value['enable']
      };
      response = await proxy.$api.post('/add_vip_team', payload);
    }

    modalVIP.value = false;
    message.success(response.data.state);
    await getData()
    modalAction.value = 'add';
  } catch (error) {
    console.error(error);
    message.error('操作失败');
  } finally {
    hideMessage();  // 关闭“加载中...”提示
  }
};

// 删除VIP
const onDeleteVIP = async (id) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/delete_vip_team', {id: id});
    message.success(response.data.state);
    await getData()
  } catch (error) {
    console.error(error);
    message.error('删除失败');
  } finally {
    hideMessage();  // 关闭“加载中...”提示
  }
};

// 修改VIP小程序可视
const changeVIPEnable = async (id, enable) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/change_vip_enable_team', {id: id, enable: enable});
    if (response.data.state === 'success') {
      modelProduct.value = {};
      // modalVisibleProduct.value = false;
    }
  } catch (error) {
    console.error(error);
    message.error('操作失败');
  } finally {
    hideMessage();  // 关闭“加载中...”提示
  }
};

// VIP列表
const columns_VIP = [
  {
    name: 'id',
    title: '类别id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '类别名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '启用',
    dataIndex: 'enable',
    key: 'enable',
  },
  {
    title: '操作',
    key: 'action',
  },
];

// -----------------------------------------------------------------------------------

const data = ref()
const loading = ref(false);
onMounted(async () => {
  loading.value = true; // 开始加载
  data.value = []
  await getData()

});
const getData = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_vip_team');
    printResponse(response.data)
  } catch (error) {
    if (error.response.status === 401) {
      message.error("未授权")
    }
  } finally {
    hideMessage();  // 关闭“加载中...”提示
  }
};
// 改变data变量的值，显示表格数据
const printResponse = (responseData) => {
  if (responseData['status'] === 'error') {

    proxy.$cookies.set('loginCheck', 'false')
    // get_data()
    router.push({name: 'LoginView'})
  }
  data.value = responseData
  loading.value = false
}
</script>

<template>
  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>VIP团队</a-breadcrumb-item>
    </a-breadcrumb>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
    >
      <div style="margin-bottom: 8px;display: flex">
        <a-modal
            v-model:open="modalVIP"
            title="增加选项"
            centered
            :footer="null"
        >
          <div class="steps-content">
            <a-form :model="VIPData"
                    :label-col="{style: { width: '150px' }}" :wrapper-col="{ span: 14 }">
              <a-form-item label="项目名称">
                <a-input v-model:value="VIPData.name"/>
              </a-form-item>
              <a-form-item label="VIP状态">
                <a-select
                    ref="select"
                    v-model:value="VIPData.enable"
                    style="width: 100%"
                >
                  <a-select-option :value=0>未启用</a-select-option>
                  <a-select-option :value=1>已启用</a-select-option>
                </a-select>
              </a-form-item>
              <!--                                        <a-form-item label="创建时间">-->
              <!--                                            <a-input v-model:value="modalData.create_time" />-->
              <!--                                        </a-form-item>-->
            </a-form>
          </div>
          <div class="steps-action">
            <a-button
                type="primary"
                @click="submitVIPChange"
            >
              提交
            </a-button>
          </div>
        </a-modal>
        <a-button class="editable-add-btn" @click="clickAddVIP">添加项目</a-button>

      </div>

      <a-table :row-selection="{ selectedRowKeys: tableSelectedRowKeys, onChange: onSelectChange }"
               :columns="columns_VIP" :data-source="data" :loading="loading"
               @change="handleTableChange">
        <template #headerCell="{ column }">
          <template v-if="column.key === 'id'">
                              <span>
                                <smile-outlined/>
                                类别id
                              </span>
          </template>
        </template>
        <template #bodyCell="{ column,record }">
          <template v-if="column.key === 'enable'">
            <a-switch v-model:checked="record.enable"
                      :checked-value="1"
                      :unchecked-value="0"
                      @click="changeVIPEnable(record.id,record.enable)">
              <template #checkedChildren>
                <check-outlined/>
              </template>
              <template #unCheckedChildren>
                <close-outlined/>
              </template>
            </a-switch>
          </template>
          <template v-if="column.key === 'action'">
                                  <span>
                                    <a @click="changeVIPData(record)">编辑</a>
                                    <a-divider type="vertical"/>
                                    <a-popconfirm
                                        v-if="data.length"
                                        title="确认删除？"
                                        @confirm="onDeleteVIP(record.id)"
                                        ok-text="确认"
                                        cancel-text="取消"
                                    >
                                      <a>删除</a>
                                    </a-popconfirm>
                                  </span>
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </div>
</template>

<style scoped>

</style>
