<script setup>

import {SmileOutlined} from "@ant-design/icons-vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import {message} from "ant-design-vue";
import router from "@/router";
const {proxy} = getCurrentInstance()

// 账户
// -----------------------------------------------------------------------------------
// 修改用户信息
const current = ref(0)
const modalUser = ref(false)
const modalAction = ref('add')
// const changePersonInfo = ref(false)
// const changePassword = ref(false)
const hospitalData = ref({"name": "", "level": "", "describe": ""})
const hospitalPic = ref("")

// const passwordInfo = ref({
//   old_password: '',
//   new_password: '',
//   re_new_password: ''
// });


// const submitPasswordChange = async () => {
//   try {
//     // 检查两次输入的密码是否相同
//     if (passwordInfo.value.re_new_password !== passwordInfo.value.new_password) {
//       message.error("两次密码输入不同，请重新输入");
//       return;
//     }
//
//     // 检查新密码的长度是否小于 8 位
//     if (passwordInfo.value.re_new_password.length < 8) {
//       message.error("密码输入小于 8 位，请重新设置密码");
//       return;
//     }
//
//     // 发送请求修改密码
//     const response = await proxy.$api.post('/change_person_password', passwordInfo.value);
//
//     // 检查响应状态并给出相应提示
//     if (response.data.state === "修改成功！") {
//       message.success(response.data.state);
//       changePassword.value = false;
//     } else {
//       message.error(response.data.state);
//     }
//   } catch (error) {
//     // 请求失败处理
//     console.error(error);
//     message.error('修改密码失败');
//   }
// };

const userOptions = ref([
  {
    value: 0,
    label: 0,
  },
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
])
const userData = ref({"name": "", "email": "", "nickname": "", "level": 0, "password": "", "re_new_password": ""})
const changeUserData = (data_current) => {
  userData.value = data_current
  modalUser.value = true
  modalAction.value = 'update'
}
// 点击添加账户
const addUserData = () => {
  userData.value = []
  modalUser.value = true
  modalAction.value = 'add'
}
// 添加、修改账号
const submitUserChange = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    let response;
    if (modalAction.value === 'update') {
      hospitalData.value['image'] = hospitalPic.value;
      response = await proxy.$api.post('/change_user', userData.value);
    } else {
      const payload = {
        name: userData.value['name'],
        nickname: userData.value['nickname'],
        email: userData.value['email'],
        password: userData.value['password'],
        level: userData.value['level']
      };
      response = await proxy.$api.post('/add_user', payload);
    }

    message.success(response.data.state);
    modalUser.value = false;
    await getData()
    modalAction.value = 'add';
  } catch (error) {
    console.error(error);
    message.error('操作失败');
  } finally {
    hideMessage();
  }
};

// 删除账号
const onDeleteUser = async (id) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    console.log(id);
    const response = await proxy.$api.post('/delete_user', {id: id});
    message.success(response.data.state);
    await getData()
  } catch (error) {
    console.error(error);
    message.error('删除失败');
  } finally {
    hideMessage()
  }
};

// 账户表单
const columns_users = [
  {
    name: 'id',
    title: '账号id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '账户名',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '别名',
    dataIndex: 'nickname',
    key: 'nickname',
  },
  {
    title: '账户邮箱',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: '操作',
    key: 'action',
  },
];
// -----------------------------------------------------------------------------------

const data = ref()
const loading = ref(false);
onMounted(async () => {
  loading.value = true; // 开始加载
  data.value = []
  await getData()

});
const getData = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_users');
    printResponse(response.data)
  } catch (error) {
    if (error.response.status === 401) {
      message.error("未授权")
    }
  } finally {
    hideMessage()
  }
};
// 改变data变量的值，显示表格数据
const printResponse = (responseData) => {
  if (responseData['status'] === 'error') {

    proxy.$cookies.set('loginCheck', 'false')
    // get_data()
    router.push({name: 'LoginView'})
  }
  data.value = responseData
  loading.value = false
}
</script>

<template>
  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>账号列表</a-breadcrumb-item>
      <!--                    <a-breadcrumb-item>List</a-breadcrumb-item>-->
      <!--                    <a-breadcrumb-item>App</a-breadcrumb-item>-->
    </a-breadcrumb>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
    >
      <div style="margin-bottom: 8px;display: flex">
        <a-modal
            v-model:open="modalUser"
            title="添加账号"
            centered
            :footer="null"
        >
          <div class="steps-content">
            <a-form v-if="current === 0" :model="userData"
                    :label-col="{style: { width: '150px' }}" :wrapper-col="{ span: 14 }">

              <a-form-item label="账户名">
                <a-input v-model:value="userData.name">
                </a-input>
              </a-form-item>
              <a-form-item label="别名">
                <a-input v-model:value="userData.nickname"/>
              </a-form-item>
              <a-form-item label="邮箱">
                <a-input v-model:value="userData.email"/>
              </a-form-item>
              <a-form-item label="密码">
                <a-input v-model:value="userData.password"/>
              </a-form-item>
              <a-form-item label="重复密码">
                <a-input v-model:value="userData.password"/>
              </a-form-item>
              <a-form-item label="账户等级">
                <a-select
                    v-model:value="userData.level"
                    style="width: 100%"
                    placeholder="账户等级"
                    :options="userOptions"
                ></a-select>
              </a-form-item>
            </a-form>
          </div>
          <div class="steps-action">
            <a-button
                type="primary"
                @click="submitUserChange"
            >
              提交
            </a-button>
          </div>
        </a-modal>
        <a-button class="editable-add-btn" @click="addUserData()">添加账户</a-button>

      </div>

      <a-table :row-selection="{ selectedRowKeys: tableSelectedRowKeys, onChange: onSelectChange }"
               :columns="columns_users" :data-source="data" :loading="loading"
               @change="handleTableChange">
        <template #headerCell="{ column }">
          <template v-if="column.key === 'id'">
                              <span>
                                <smile-outlined/>
                                账户id
                              </span>
          </template>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'action'">
                                  <span>
                                    <a @click="changeUserData(record)">编辑</a>
                                    <a-divider type="vertical"/>
                                    <a-popconfirm
                                        v-if="data.length"
                                        title="确认删除？"
                                        @confirm="onDeleteUser(record.id)"
                                        ok-text="确认"
                                        cancel-text="取消"
                                    >
                                      <a>删除</a>
                                    </a-popconfirm>
                                  </span>
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </div>
</template>

<style scoped>

</style>
