<template>
  <a-layout v-if="loginCheck===true" id="index">
    <a-layout>
      <a-layout-sider width="200" style="background: #fff;position: fixed;height: 100%">
        <div class="logo">
          <a-typography-title :level="3">吉康医疗</a-typography-title>
        </div>
        <a-menu
            mode="inline"
            :style="{ height: '100%', borderRight: 0}"
        >
          <a-menu-item key="1">
            <template #icon>
              <DashboardOutlined/>
            </template>
            <router-link to="/">数据总览</router-link>
          </a-menu-item>
          <a-menu-item key="2" v-if="user_info.level >= 2">
            <template #icon>
              <PictureOutlined/>
            </template>
            <router-link to="/carousel">轮播图</router-link>
          </a-menu-item>
          <a-menu-item key="3" v-if="user_info.level >= 2">
            <template #icon>
              <HomeOutlined/>
            </template>
            <router-link to="/hospital-list">医院列表</router-link>
          </a-menu-item>
          <a-menu-item key="4" v-if="user_info.level >= 2">
            <template #icon>
              <UnorderedListOutlined/>
            </template>
            <router-link to="/product-list">产品列表</router-link>
          </a-menu-item>
          <a-menu-item key="5" v-if="user_info.level >= 2">
            <template #icon>
              <UsergroupAddOutlined/>
            </template>
            <router-link to="/accompany-service">陪诊服务</router-link>
          </a-menu-item>
          <a-menu-item key="6" v-if="user_info.level >= 2">
            <template #icon>
              <UsergroupAddOutlined/>
            </template>
            <router-link to="/assistance">医疗辅助</router-link>
          </a-menu-item>
          <a-menu-item key="7" v-if="user_info.level >= 2">
            <template #icon>
              <UsergroupAddOutlined/>
            </template>
            <router-link to="/examination">体检服务</router-link>
          </a-menu-item>
          <a-menu-item key="8" v-if="user_info.level >= 2">
            <template #icon>
              <UsergroupAddOutlined/>
            </template>
            <router-link to="/vip-customization">VIP服务</router-link>
          </a-menu-item>
          <a-menu-item key="9" v-if="user_info.level >= 2">
            <template #icon>
              <UsergroupAddOutlined/>
            </template>
            <router-link to="/vip-team">VIP团队</router-link>
          </a-menu-item>
          <a-menu-item key="10">
            <template #icon>
              <PayCircleOutlined/>
            </template>
            <router-link to="/order-list">订单列表</router-link>
          </a-menu-item>
          <a-menu-item key="11">
            <template #icon>
              <PayCircleOutlined/>
            </template>
            <router-link to="/lottery-settings">抽奖设置</router-link>
          </a-menu-item>
          <a-menu-item key="12">
            <template #icon>
              <UserOutlined/>
            </template>
            <router-link to="/user-settings">个人设置</router-link>
          </a-menu-item>
          <a-menu-item key="13">
            <template #icon>
              <UserOutlined/>
            </template>
            <router-link to="/user-list">用户设置</router-link>
          </a-menu-item>
          <a-menu-item key="14" v-if="user_info.level === 3">
            <template #icon>
              <UserSwitchOutlined/>
            </template>
            <router-link to="/account-management">账号管理</router-link>
          </a-menu-item>

          <!--                    <a-menu-item key="2">-->
          <!--                        <template #icon>-->
          <!--                            <AppstoreOutlined/>-->
          <!--                        </template>-->
          <!--                        <span>功能</span>-->
          <!--                    </a-menu-item>-->
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header style="background: #ffffff;width: 100%">
          <a-row justify="end" style="width: 100%">
            <a-col :span="24" style="display: flex; justify-content: flex-end;">
              <a-dropdown>
                <a class="ant-dropdown-link" @click.prevent>
                  个人中心
                  <DownOutlined/>
                </a>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <a href="javascript:;">个人设置</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a @click="login_out">退出登录</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </a-col>
          </a-row>

        </a-layout-header>
        <a-layout-content style="padding: 0 24px 24px;margin-left: 200px">
          <router-view></router-view>
        </a-layout-content>
        <a-layout-footer>Footer</a-layout-footer>
      </a-layout>
    </a-layout>
  </a-layout>

</template>

<script setup>
import {
  DashboardOutlined,
  DownOutlined,
  HomeOutlined,
  PayCircleOutlined,
  PictureOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined
} from '@ant-design/icons-vue';
import {message} from 'ant-design-vue';
// import axios from "axios";
const {proxy} = getCurrentInstance()
import {getCurrentInstance, onMounted,ref} from "vue";
import {useStore} from 'vuex';
import router from "@/router";
const store = useStore();
// let data = ref([])
// let loading = ref(true)
const loginCheck = ref(false)

// 操作表单
// const tableSelectedRowKeys = ref([])
// 多选操作
// const onSelectChange = selectedRowKeys => {
//   console.log('selectedRowKeys changed: ', selectedRowKeys);
//   tableSelectedRowKeys.value = selectedRowKeys;
// };
// 多选删除
// const delectSelectTable = () => {
//     // console.log(tableSelectedRowKeys.value[0])
//     // formattedString = tableSelectedRowKeys.value.join(', ')
//     axios
//         .post('http://127.0.0.1:5000/delete_data', qs.stringify({id: tableSelectedRowKeys.value.join(',')}))
//         .then(response => (handelResponse(response['data'])))
//         .catch(function (error) { // 请求失败处理
//             console.log(error);
//         });
//     // console.log("调用删除按钮")
// };
// 改变data变量的值，显示表格数据
// const printResponse = (responseData) => {
//   if (responseData['status'] === 'error') {
//
//     proxy.$cookies.set('loginCheck', 'false')
//     // get_data()
//     loginCheck.value = false
//   }
//   data.value = responseData
//   loading.value = false
// }

// 登出
const login_out = () => {
  proxy.$cookies.set('loginCheck', 'false')
  // get_data()
  loginCheck.value = false
  store.commit("clearUserData");
  message.success('已注销');
}
let token = ""
let user_info = ref({})

onMounted(async () => {
  token = localStorage.getItem('token');
  if (token) {
    store.commit("set_token", token);

  }
  // console.log(proxy)
  try {
    const response = await proxy.$api.post('/get_user_info');
    user_info.value = response.data;
    loginCheck.value = true;
  } catch (error) {
    console.error(error);
    await router.push({name: 'LoginView'})
  }
});
// 吉康医疗
// 设置初始菜单为 1
// -----------------------------------------------------------------------------------
// const apiEndpoints = {
//   '2': 'get_carousel',
//   '3': 'get_hospitals',
//   '4': 'get_products',
//   '5': 'get_vip',
//   '6': 'get_orders',
//   '7': 'get_lottery',
//   '8': 'get_orders',
//   '9': 'get_users'
// };
// const menu_key = ref('1');
// const click_menu = async (e) => {
//   loading.value = true; // 开始加载
//   data.value = []
//   menu_key.value = e.key;
//   const endpoint = apiEndpoints[e.key];
//   if (endpoint) {
//     try {
//       const response = await proxy.$api.post(`/${endpoint}`);
//       printResponse(response.data)
//     } catch (error) {
//       if (error.response.status === 401) {
//         message.error("未授权")
//       }
//     }
//   }
// };

// -----------------------------------------------------------------------------------












</script>

<style scoped>
.title {
  font-size: 28px;
  color: rgba(0, 0, 0, .85);
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
  text-align: center;
  margin: 20px 0;
}

#app {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: #f5f5f5;
}

#index,
#index .a-layout .ant-layout-sider {
  width: 100%;
  height: 100%;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 20px;
}

.steps-action {
  display: flex;
  justify-content: right;
  margin-top: 24px;
}

[data-theme='dark'] .steps-content {
  background-color: #2f2f2f;
  border: 1px dashed #404040;
}

.content {
  height: 38px;
  margin-top: 4px;
  margin-bottom: 0;
  overflow: hidden;
  font-size: 30px;
  line-height: 38px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.logo {
  /*height: 64px;*/
  /*background: rgba(255, 255, 255, 0.2);*/
  /*margin: 16px;*/
  /*height: 80px;*/
  text-align: center;
  user-select: none; /* 标准语法 */
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Chrome/Safari */
  -ms-user-select: none; /* IE/Edge */
}

/*.logo img {*/
/*    height: 100%; !* 或者其他尺寸，根据需要调整 *!*/
/*}*/
</style>