<script setup>

import dayjs from "dayjs";
// import {getCurrentInstance, onMounted, ref} from "vue";
import { getCurrentInstance, onMounted, ref, computed } from "vue";

import {message} from "ant-design-vue";
import router from "@/router";

const {proxy} = getCurrentInstance()
const modalAction = ref("add")
// 订单
// -----------------------------------------------------------------------------------
const searchQuery = ref(""); // 搜索输入框的绑定
const selectedStatus = ref(null); // 订单状态选择的绑定
// 点击添加订单
const current = ref(0)
const modalOrder = ref(false)
const orderOptions = ref([
  {
    value: '未付款',
    label: '未付款',
  },
  {
    value: '已付款',
    label: '已付款',
  },
  {
    value: '已完成',
    label: '已完成',
  },
  {
    value: '售后',
    label: '售后',
  }
]);
const yuanPrice = (yuan) => {
  return yuan / 100;
}
// const fenPrice = (yuan) => {
//   return yuan * 100;
// }
const orderData = ref({"order_id":"","order_user": "", "order_date": "", "product_id": "", "order_status": "未付款", "actual_payment": "","prize_name":""})
// 订单表单
const columns_orders = [
  {
    name: 'id',
    title: '订单id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    name: 'order_id',
    title: '订单编号',
    dataIndex: 'order_id',
    key: 'order_id',
  },
  {
    title: '购买人',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: '购买日期',
    dataIndex: 'order_date',
    key: 'order_date',
  },
  {
    title: '购买物品',
    dataIndex: 'product',
    key: 'product',
  },
  {
    title: '所在医院',
    dataIndex: 'hospital',
    key: 'hospital',
  },
  {
    title: '订单状态',
    dataIndex: 'order_status',
    filters: [
      {
        value: '未付款',
        label: '未付款',
      },
      {
        value: '已付款',
        label: '已付款',
      },
      {
        value: '已完成',
        label: '已完成',
      },
      {
        value: '售后',
        label: '售后',
      }
    ],
    onFilter: (value, record) => record.order_status.indexOf(value) === 0,

    key: 'order_status',
  },
  {
    title: '订单价格',
    dataIndex: 'actual_payment',
    key: 'actual_payment',
  },
  {
    title: '优惠券',
    dataIndex: 'prize_name',
    key: 'prize_name',
  },
  {
    title: '操作',
    key: 'action',
  },
];
const addOrderData = () => {
  orderData.value = []
  modalOrder.value = true
  modalAction.value = 'add'
}
// 添加订单信息
const submitOrderChange = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    let response;
    const payload = {
      order_user: orderData.value['order_user'],
      order_date: orderData.value['order_date'].format('YYYY-MM-DD HH:mm:ss'),
      product_id: orderData.value['product_id'],
      order_status: orderData.value['order_status'],
      price: orderData.value['price'],
    };

    if (modalAction.value === 'add') {
      response = await proxy.$api.post('/add_order', payload);
    } else if (modalAction.value === 'update') {
      payload.id = orderData.value['id'];
      response = await proxy.$api.post('/change_order', payload);
    }

    orderData.value = [];
    modalOrder.value = false;
    await getData()
    message.success(response.data.state);
  } catch (error) {
    console.error(error);
    message.error('操作失败');
  }
 finally {
  hideMessage();  // 关闭“加载中...”的提示
}}

// 修改订单按钮
const changeOrderData = (data_current) => {
  // if (data_current['price_type']!=="yuan"){
  //   data_current['price_type'] = "yuan"
  // }
  orderData.value = data_current
  orderData.value['order_date'] = dayjs(orderData.value['order_date'], 'YYYY-MM-DD HH:mm:ss')
  modalOrder.value = true
  modalAction.value = 'update'
}


const onOrderDelete = async (id) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/delete_order', { id });
    message.success(response.data.state);

    await getData()

  } catch (error) {
    console.error(error);
    message.error('删除失败');
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
};


// const refreshOrderData = async () => {
//   loading.value = true;
//   try {
//     const response = await proxy.$api.post('/get_orders');
//     printResponse(response.data);
//   } catch (error) {
//     if (error.response && error.response.status === 401) {
//       message.error("未授权");
//     }
//   } finally {
//     loading.value = false;
//   }
// };




// 根据不同状态查询订单


// -----------------------------------------------------------------------------------

const data = ref([]); // 确保初始值为一个空数组
const loading = ref(false);
onMounted(async () => {
  loading.value = true; // 开始加载
  data.value = []
  await getData()

});
const getData = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_orders');
    printResponse(response.data)
  } catch (error) {
    if (error.response.status === 401) {
      message.error("未授权")
    }
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
};
// 改变data变量的值，显示表格数据
const printResponse = (responseData) => {
  if (responseData['status'] === 'error') {

    proxy.$cookies.set('loginCheck', 'false')
    // get_data()
    router.push({name: 'LoginView'})
  }
  data.value = responseData
  loading.value = false
}

// 计算属性，返回格式化后的价格
const formattedData = computed(() => {
  return data.value.map(order => ({
    ...order,
    price: yuanPrice(order.price), // 转换为元，仅在展示时
  }));
});

// 过滤后的数据
const filteredData = computed(() => {
  return Array.isArray(formattedData.value) ? formattedData.value.filter(order => {
    const matchesSearch =
        (order.user || "").includes(searchQuery.value) ||
        (order.product || "").includes(searchQuery.value) ||
        (order.order_id || "").includes(searchQuery.value) ||
        (order.hospital || "").includes(searchQuery.value);

    const matchesStatus = !selectedStatus.value || order.order_status === selectedStatus.value;

    return matchesSearch && matchesStatus;
  }) : []; // 确保返回一个空数组
});


const drawerVisible = ref(false);
const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
  const date = new Date(dateString);
  return date.toLocaleString('zh-CN', options); // 根据需要选择语言
};
const displayOrderData = async (data_current) => {
  loading.value = true;
  drawerVisible.value = true;
  // data_current['price'] = data_current['price']
  orderData.value = data_current
  orderData.value['order_date'] = dayjs(orderData.value['order_date'], 'YYYY-MM-DD HH:mm:ss')
  drawerVisible.value = true
  loading.value = false;
};

const closeDrawer = () => {
  drawerVisible.value = false;
};


</script>

<template>
  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>订单列表</a-breadcrumb-item>
    </a-breadcrumb>
    <a-drawer
        title="订单详情"
        :visible="drawerVisible"
        @close="closeDrawer"
        :width="600"
    >
      <a-spin :spinning="loading">
        <div v-if="!loading">
          <a-descriptions bordered>
            <a-descriptions-item label="商品名称">{{ orderData.product }}</a-descriptions-item>
            <a-descriptions-item label="商品医院" :span="2">{{ orderData.hospital }}</a-descriptions-item>
            <a-descriptions-item label="订单编号" :span="3">{{ orderData.order_id }}</a-descriptions-item>
            <a-descriptions-item label="订单状态">{{ orderData.order_status }}</a-descriptions-item>
            <a-descriptions-item label="下单时间" :span="2">{{ formatDate(orderData.order_date) }}</a-descriptions-item>
            <a-descriptions-item label="定价价格">{{ orderData.price }}</a-descriptions-item>
            <a-descriptions-item label="实际付款">{{ orderData.actual_payment }}</a-descriptions-item>
            <a-descriptions-item label="定价标记">{{ orderData.mark }}</a-descriptions-item>
            <a-descriptions-item label="折扣情况" :span="3">{{ orderData.prize_name }}</a-descriptions-item>
<!--            <a-descriptions-item label="Status" >-->
<!--              <a-badge status="processing" text="Running" />-->
<!--            </a-descriptions-item>-->
            <a-descriptions-item label="姓名" :span="3">{{ orderData.username }}</a-descriptions-item>
            <a-descriptions-item label="订单电话" :span="3">{{ orderData.phone_number }}</a-descriptions-item>
            <a-descriptions-item label="订单地址" :span="3">{{ orderData.address }}{{ orderData.detail }}</a-descriptions-item>
          </a-descriptions>
        </div>
        <div v-else>
          正在加载...
        </div>
      </a-spin>
    </a-drawer>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
    >
      <div style="margin-bottom: 8px; display: flex; align-items: center;">
        <!-- 搜索输入框 -->
        <a-input
            v-model:value="searchQuery"
            placeholder="搜索订单"
            style="width: 200px; margin-right: 8px;"
            @input="filterOrders"
        />

        <a-modal
            v-model:open="modalOrder"
            title="添加订单"
            centered
            :footer="null"
        >
          <div class="steps-content">
            <a-form v-if="current === 0" :model="orderData"
                    :label-col="{style: { width: '150px' }}" :wrapper-col="{ span: 14 }">

              <a-form-item label="购买人">
                <a-input v-model:value="orderData.user" placeholder="购买人ID">
                  <template #suffix>
                    <a-tooltip title="Extra information">
                      <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)"/>
                    </a-tooltip>
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item label="购买日期">
                <a-date-picker
                    v-model:value="orderData.order_date"
                    placeholder="选择购买时间"
                    format="YYYY-MM-DD HH:mm:ss"
                    :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }"
                    style="width: 100%"
                />
              </a-form-item>
              <a-form-item label="购买物品">
                <a-input v-model:value="orderData.product"/>
              </a-form-item>
              <a-form-item label="订单状态">
                <a-select
                    v-model:value="orderData.order_status"
                    style="width: 100%"
                    placeholder="订单状态"
                    :options="orderOptions"
                ></a-select>
              </a-form-item>
              <a-form-item label="订单价格">
                <a-input v-model:value="orderData.actual_payment" prefix="￥"/>
              </a-form-item>
              <a-form-item label="联系电话">
                <a-input v-model:value="orderData.phone_number"/>
              </a-form-item>
              <a-form-item label="配送地址">
                <a-input v-model:value="orderData.address"/>
              </a-form-item>
              <a-form-item label="门牌号">
                <a-input v-model:value="orderData.detail"/>
              </a-form-item>
              <a-form-item label="订单标记">
                <a-input v-model:value="orderData.mark"/>
              </a-form-item>
            </a-form>
          </div>
          <div class="steps-action">
            <a-button
                type="primary"
                @click="submitOrderChange"
            >
              提交
            </a-button>
          </div>
        </a-modal>
        <a-button class="editable-add-btn" @click="addOrderData()">添加订单</a-button>
      </div>

      <a-table :row-selection="{ selectedRowKeys: tableSelectedRowKeys, onChange: onSelectChange }"
               :columns="columns_orders" :data-source="filteredData" :loading="loading"
               @change="handleTableChange">
        <template #headerCell="{ column }">
          <template v-if="column.key === 'id'">
            <span>
              <smile-outlined/>
              订单id
            </span>
          </template>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'url'">
            <a>
              {{ record.url }}
            </a>
          </template>
          <template v-if="column.key === 'order_date'">
            <a-tooltip>
              {{ formatDate(record.order_date) }}
            </a-tooltip>
          </template>
          <template v-if="column.key === 'order_status'">
            <a-badge v-if="record.order_status==='售后'" color="#f50"
                     :text="record.order_status"/>
            <a-badge v-if="record.order_status==='已付款'" color="#2db7f5"
                     :text="record.order_status"/>
            <a-badge v-if="record.order_status==='未付款'" color="#108ee9"
                     :text="record.order_status"/>
            <a-badge v-if="record.order_status==='已完成'" color="#87d068"
                     :text="record.order_status"/>
          </template>
          <template v-if="column.key === 'actual_payment'">
            <a-tooltip>
              <!-- 根据 vip_type 的值显示对应文本 -->
              {{ yuanPrice(record.actual_payment) }}
            </a-tooltip>
          </template>
          <template v-else-if="column.key === 'action'">
            <span>
              <a @click="displayOrderData(record)">详细</a>
              <a-divider type="vertical"/>
              <a @click="changeOrderData(record)">编辑</a>
              <a-divider type="vertical"/>
              <a-popconfirm
                  v-if="data.length"
                  title="确认删除？"
                  @confirm="onOrderDelete(record.id)"
                  ok-text="确认"
                  cancel-text="取消"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </div>
</template>

<style scoped>
.steps-action {
  display: flex;
  justify-content: right;
  margin-top: 24px;
}
</style>
