<script setup>

// import {getCurrentInstance, onMounted, ref} from "vue";
import { getCurrentInstance, onMounted, ref, computed } from "vue";

import {message} from "ant-design-vue";
import router from "@/router";

const {proxy} = getCurrentInstance()
const modalAction = ref("add")
// 用户
// -----------------------------------------------------------------------------------
const searchQuery = ref(""); // 搜索输入框的绑定
const selectedStatus = ref(null); // 用户状态选择的绑定
// 点击添加用户
const current = ref(0)
const modalUser = ref(false)
// const yuanPrice = (yuan) => {
//   return yuan / 100;
// }
// const fenPrice = (yuan) => {
//   return yuan * 100;
// }
const UserData = ref({"id": "", "openid": "", "nickName": "", "avatarUrl": "", "username": "", "phone_number": "", "region_1": "", "region_2": "", "region_3": "", "address": "", "lottery_number": "",})
// 用户表单
const columns_Users = [
  {
    name: 'id',
    title: '用户id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    name: 'openid',
    title: '微信id',
    dataIndex: 'openid',
    key: 'openid',
  },
  {
    title: '用户昵称',
    dataIndex: 'nickName',
    key: 'nickName',
  },
  {
    title: '用户头像',
    dataIndex: 'avatarUrl',
    key: 'avatarUrl',
  },
  {
    title: '购买姓名',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: '手机号',
    dataIndex: 'phone_number',
    key: 'phone_number',
  },
  {
    title: '收货地址',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: '门票号',
    dataIndex: 'address_detail',
    key: 'address_detail',
  },
  {
    title: '抽奖次数',
    dataIndex: 'lottery_number',
    key: 'lottery_number',
  },
  {
    title: '奖品列表',
    dataIndex: 'prizes',
    key: 'prizes',
  },
  {
    title: '操作',
    key: 'action',
  },
];
const addUserData = () => {
  UserData.value = []
  modalUser.value = true
  modalAction.value = 'add'
}
// 添加用户信息
const submitUserChange = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    let response;
    const payload = {
      openid: UserData.value['User_user'],
      nickname: UserData.value['nickname'],
      avatarurl: UserData.value['avatarurl'],
      username: UserData.value['username'],
      phone_number: UserData.value['phone_number'],
      address_detail: UserData.value['address_detail'],
      address: UserData.value['address'],
      lottery_number: UserData.value['lottery_number'],
    };

    if (modalAction.value === 'add') {
      response = await proxy.$api.post('/add_wx_user', payload);
    } else if (modalAction.value === 'update') {
      payload.id = UserData.value['id'];
      response = await proxy.$api.post('/change_wx_user', payload);
    }

    UserData.value = [];
    modalUser.value = false;
    await getData()
    message.success(response.data.state);
  } catch (error) {
    console.error(error);
    message.error('操作失败');
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
};

// 修改用户按钮
const changeUserData = (data_current) => {
  UserData.value = data_current
  modalUser.value = true
  modalAction.value = 'update'
}


const onUserDelete = async (id) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/delete_wx_user', { id });
    message.success(response.data.state);

    await getData()

  } catch (error) {
    console.error(error);
    message.error('删除失败');
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
};

// const refreshUserData = async () => {
//   loading.value = true;
//   try {
//     const response = await proxy.$api.post('/get_wx_users');
//     printResponse(response.data);
//   } catch (error) {
//     if (error.response && error.response.status === 401) {
//       message.error("未授权");
//     }
//   } finally {
//     loading.value = false;
//   }
// };




// 根据不同状态查询用户


// -----------------------------------------------------------------------------------

const data = ref([]); // 确保初始值为一个空数组
const loading = ref(false);
onMounted(async () => {
  loading.value = true; // 开始加载
  data.value = []
  await getData()

});
const getData = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_wx_users');
    printResponse(response.data)
  } catch (error) {
    if (error.response.status === 401) {
      message.error("未授权")
    }
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
};
// 改变data变量的值，显示表格数据
const printResponse = (responseData) => {
  if (responseData['status'] === 'error') {

    proxy.$cookies.set('loginCheck', 'false')
    // get_data()
    router.push({name: 'LoginView'})
  }
  data.value = responseData
  loading.value = false
}

const isModalVisible = ref(false);

// 奖品列表
const prizeList = ref([
]);

// 奖品表单
// const prizeForm = ref({
//   id: null,
//   prize_name: '',
//   prize_type: '',
//   description: '',
//   valid_until: null,
//   prize_user: '',
//   prize_amount: 0,
//   used: false,
// });

// 表格列
const columns = [
  { title: '奖品名称', dataIndex: 'prize_name', key: 'prize_name' },
  { title: '奖品类型', dataIndex: 'prize_type', key: 'prize_type' },
  { title: '描述', dataIndex: 'description', key: 'description' },
  { title: '有效期', dataIndex: 'valid_until', key: 'valid_until' },
  { title: '折扣额度', dataIndex: 'prize_amount', key: 'prize_amount' },
  { title: '用户', dataIndex: 'prize_user', key: 'prize_user' },
  { title: '是否已使用', dataIndex: 'used', key: 'used' },
  { title: '操作', key: 'action'},
];

// 打开模态框并加载数据
const changePrizesData = (openid) => {
  prizeList.value = []
  isModalVisible.value = true;
  // 模拟从后端获取奖品数据
  loadPrizeList(openid);
};



// 处理确认按钮
// const handleOk = () => {
//   if (prizeForm.value.id) {
//     updatePrize();
//   }
//   else {
//     addPrize();
//   }
//   isModalVisible.value = false;
//   resetPrizeForm();
// };
//
// // 处理取消按钮
// const handleCancel = () => {
//   isModalVisible.value = false;
//   resetPrizeForm();
// };
// 模拟加载奖品数据
const loadPrizeList = async (openid) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_prizes', { openid: openid });
    prizeList.value = response.data;
    message.success(`加载奖品数据: ${prizeList.value.length}条`);
  } catch (error) {
    message.error('加载奖品数据失败');
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
};

// 添加奖品
// const addPrize = async () => {
//   try {
//     const response = await proxy.$api.post('/add_prize', prizeForm);
//     prizeList.value.push(response.data);
//     message.success('奖品添加成功');
//   } catch (error) {
//     message.error('奖品添加失败');
//   }
// };

// 编辑奖品
// const editPrize = async (record) => {
//   Object.assign(prizeForm, record);
//   isModalVisible.value = true;
// };

// 删除奖品
const deletePrize = async (id) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    await proxy.$api.post('/delete_prize', { id });
    prizeList.value = prizeList.value.filter((prize) => prize.id !== id);
    message.success('奖品删除成功');
  } catch (error) {
    message.error('奖品删除失败');
  } finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
};

// 更新奖品
// 更新奖品
// const updatePrize = async () => {
//   try {
//     // 发送更新奖品请求
//     const response = await proxy.$api.post('/update_prize', prizeForm);
//
//     // 检查服务器响应的状态码或者响应内容，确保请求成功
//     if (response && response.data.success) { // 假设服务器返回 { success: true }
//       const index = prizeList.value.findIndex((prize) => prize.id === prizeForm.value.id);
//
//       // 如果找到对应奖品则更新奖品数据
//       if (index !== -1) {
//         prizeList.value.splice(index, 1, { ...prizeForm });
//         message.success('奖品更新成功');
//       }
//     } else {
//       // 如果服务器返回的 success 字段为 false 或者其他错误信息
//       message.error(response.data.message || '奖品更新失败');
//     }
//   } catch (error) {
//     // 捕获异常并处理
//     message.error('服务器错误，奖品更新失败');
//   }
// };



// 重置表单
// const resetPrizeForm = () => {
//   Object.assign(prizeForm, {
//     id: null,
//     prize_name: '',
//     prize_type: '',
//     description: '',
//     valid_until: null,
//     prize_user: '',
//     prize_amount: 0,
//     used: false,
//   });
// };

// 显示添加奖品模态框
// const showAddPrizeModal = () => {
//   resetPrizeForm();
//   isModalVisible.value = true;
// };

// 过滤后的数据
const filteredData = computed(() => {
  return Array.isArray(data.value) ? data.value.filter(User => {
    const matchesSearch =
        (User.openid || "").includes(searchQuery.value) ||
        (User.nickName || "").includes(searchQuery.value) ||
        (User.username || "").includes(searchQuery.value) ||
        (User.phone_number || "").includes(searchQuery.value) ||
        (User.address || "").includes(searchQuery.value) ||
        (User.address_detail || "").includes(searchQuery.value)

    const matchesStatus = !selectedStatus.value || User.User_status === selectedStatus.value;

    return matchesSearch && matchesStatus;
  }) : []; // 确保返回一个空数组
});


</script>

<template>
  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>用户列表</a-breadcrumb-item>
    </a-breadcrumb>
    <!-- 奖品模态框 -->
    <a-modal
        v-model:visible="isModalVisible"
        title="奖品管理"
        width="100%"
        :footer="null"
    >
      <!-- 添加奖品按钮 -->
<!--      <a-button type="primary" @click="showAddPrizeModal">添加奖品</a-button>-->

      <!-- 奖品列表 -->
      <a-table :columns="columns" :dataSource="prizeList" rowKey="id">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'prize_type'">
            <a-tooltip :title="record.prize_type">
              <a-text ellipsis :rows="1">
                <!-- 根据 vip_type 的值显示对应文本 -->
                {{
                  record.prize_type === "discount" ? '折扣券' :
                      record.prize_type === "physical" ? '实物券' :
                          '未知类型'
                }}
              </a-text>
            </a-tooltip>
          </template>
          <template v-if="column.key === 'used'">
            <a-tooltip :title="record.used">
              <a-text ellipsis :rows="1">
                <!-- 根据 vip_type 的值显示对应文本 -->
                {{
                  record.used === 0 ? '未使用' :
                      record.used === 1 ? '已使用' :
                                      '未知类型'
                }}
              </a-text>
            </a-tooltip>
          </template>
          <template v-if="column.key === 'action'">
            <span>
<!--              <a @click="addPrize(record)">添加</a>-->
<!--              <a-divider type="vertical"/>-->
<!--              <a @click="editPrize(record)">编辑</a>-->
<!--              <a-divider type="vertical"/>-->
              <a-popconfirm
                  v-if="data.length"
                  title="确认删除？"
                  @confirm="deletePrize(record.id)"
                  ok-text="确认"
                  cancel-text="取消"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </template>
        </template>
      </a-table>
    </a-modal>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
    >
      <div style="margin-bottom: 8px; display: flex; align-items: center;">
        <!-- 搜索输入框 -->
        <a-input
            v-model:value="searchQuery"
            placeholder="搜索用户"
            style="width: 200px; margin-right: 8px;"
            @input="filteredData"
        />

        <a-modal
            v-model:open="modalUser"
            title="添加用户"
            centered
            :footer="null"
        >
          <div class="steps-content">
            <a-form v-if="current === 0" :model="UserData"
                    :label-col="{style: { width: '150px' }}" :wrapper-col="{ span: 14 }">

              <a-form-item label="OpenID">
                <a-input v-model:value="UserData.openid" placeholder="输入用户 OpenID" />
              </a-form-item>

              <a-form-item label="昵称">
                <a-input v-model:value="UserData.nickName" placeholder="输入昵称" />
              </a-form-item>

              <a-form-item label="头像 URL">
                <a-input v-model:value="UserData.avatarUrl" placeholder="输入头像 URL" />
              </a-form-item>

              <a-form-item label="用户名">
                <a-input v-model:value="UserData.username" placeholder="输入用户名" />
              </a-form-item>

              <a-form-item label="联系电话">
                <a-input v-model:value="UserData.phone_number" placeholder="输入联系电话" />
              </a-form-item>

              <a-form-item label="地址">
                <a-input v-model:value="UserData.address" placeholder="输入地址" />
              </a-form-item>
              <a-form-item label="门牌号">
                <a-input v-model:value="UserData.address_detail" placeholder="输入门牌号" />
              </a-form-item>
              <a-form-item label="抽奖次数">
                <a-input-number v-model:value="UserData.lottery_number" placeholder="输入抽奖次数" />
              </a-form-item>
            </a-form>
          </div>
          <div class="steps-action">
            <a-button
                type="primary"
                @click="submitUserChange"
            >
              提交
            </a-button>
          </div>
        </a-modal>
        <a-button class="editable-add-btn" @click="addUserData()">添加用户</a-button>
      </div>

      <a-table :row-selection="{ selectedRowKeys: tableSelectedRowKeys, onChange: onSelectChange }"
               :columns="columns_Users" :data-source="filteredData" :loading="loading"
               @change="handleTableChange">
        <template #headerCell="{ column }">
          <template v-if="column.key === 'id'">
            <span>
              <smile-outlined/>
              用户id
            </span>
          </template>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'avatarUrl'">
            <a-image
                :width="100"
                :src="record.avatarUrl"
            />
          </template>
          <template v-else-if="column.key === 'prizes'">
            <span>
              <a @click="changePrizesData(record.openid)">查看</a>
            </span>
          </template>
          <template v-else-if="column.key === 'action'">
            <span>
              <a @click="changeUserData(record)">编辑</a>
              <a-divider type="vertical"/>
              <a-popconfirm
                  v-if="data.length"
                  title="确认删除？"
                  @confirm="onUserDelete(record.id)"
                  ok-text="确认"
                  cancel-text="取消"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </div>
</template>

<style scoped>

</style>
