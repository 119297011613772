<script setup>

import {SmileOutlined} from "@ant-design/icons-vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import {message} from "ant-design-vue";
import router from "@/router";
const {proxy} = getCurrentInstance()
// 陪诊服务
// -----------------------------------------------------------------------------------
// 陪诊服务
const modalService = ref(false)
const modalAction = ref("add")
const modelProduct = ref()

const serviceData = ref({'id': '', 'name': '', 'enable': ''})
// 修改陪诊服务信息
const changeServiceData = (data) => {
  console.log(data)
  serviceData.value = data
  modalService.value = true
  modalAction.value = 'update'
}
// 点击添加陪诊服务按钮
const clickAddService = () => {
  serviceData.value = []
  modalAction.value = 'add'
  modalService.value = !modalService.value
}
// 提交服务信息
const submitServiceChange = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭
  try {
    let response;
    if (modalAction.value === 'update') {
      response = await proxy.$api.post('/change_accompanying', serviceData.value);
    } else {
      const payload = {
        name: serviceData.value['name'],
        enable: serviceData.value['enable']
      };
      response = await proxy.$api.post('/add_accompanying', payload);
    }

    modalService.value = false;
    message.success(response.data.state);
    await getData();
    modalAction.value = 'add';
  } catch (error) {
    console.error(error);
    message.error('操作失败');
  }finally {
    hideMessage();  // 关闭“加载中...”的提示
  }
};

// 删除陪诊服务
const onDeleteService = async (id) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/delete_accompanying', {id: id});
    message.success(response.data.state);
    await getData();
  } catch (error) {
    console.error(error);
    message.error('删除失败');
  } finally {
    hideMessage()
  }
};

// 修改陪诊服务状态
const changeServiceEnable = async (id, enable) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/change_accompanying_enable', {id: id, enable: enable});
    if (response.data.state === 'success') {
      modelProduct.value = {};
      await getData();
    }
  } catch (error) {
    console.error(error);
    message.error('操作失败');
  } finally {
    hideMessage()
  }
};

// 陪诊服务列表
const columns_service = [
  {
    name: 'id',
    title: '服务id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '服务名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '启用',
    dataIndex: 'enable',
    key: 'enable',
  },
  {
    title: '操作',
    key: 'action',
  },
];

// -----------------------------------------------------------------------------------

const data = ref()
const loading = ref(false);
onMounted(async () => {
  loading.value = true; // 开始加载
  data.value = []
  await getData()

});
const getData = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_accompanying');
    printResponse(response.data)
  } catch (error) {
    if (error.response.status === 401) {
      message.error("未授权")
    }
  } finally {
    hideMessage()
  }
};
// 改变data变量的值，显示表格数据
const printResponse = (responseData) => {
  if (responseData['status'] === 'error') {

    proxy.$cookies.set('loginCheck', 'false')
    router.push({name: 'LoginView'})
  }
  data.value = responseData
  loading.value = false
}
</script>

<template>
  <div>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>陪诊服务</a-breadcrumb-item>
    </a-breadcrumb>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
    >
      <div style="margin-bottom: 8px;display: flex">
        <a-modal
            v-model:open="modalService"
            title="增加选项"
            centered
            :footer="null"
        >
          <div class="steps-content">
            <a-form :model="serviceData"
                    :label-col="{style: { width: '150px' }}" :wrapper-col="{ span: 14 }">
              <a-form-item label="服务名称">
                <a-input v-model:value="serviceData.name"/>
              </a-form-item>
              <a-form-item label="服务状态">
                <a-select
                    ref="select"
                    v-model:value="serviceData.enable"
                    style="width: 100%"
                >
                  <a-select-option :value=0>未启用</a-select-option>
                  <a-select-option :value=1>已启用</a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
          </div>
          <div class="steps-action">
            <a-button
                type="primary"
                @click="submitServiceChange"
            >
              提交
            </a-button>
          </div>
        </a-modal>
        <a-button class="editable-add-btn" @click="clickAddService">添加项目</a-button>

      </div>

      <a-table :row-selection="{ selectedRowKeys: tableSelectedRowKeys, onChange: onSelectChange }"
               :columns="columns_service" :data-source="data" :loading="loading"
               @change="handleTableChange">
        <template #headerCell="{ column }">
          <template v-if="column.key === 'id'">
                              <span>
                                <smile-outlined/>
                                服务id
                              </span>
          </template>
        </template>
        <template #bodyCell="{ column,record }">
          <template v-if="column.key === 'enable'">
            <a-switch v-model:checked="record.enable"
                      :checked-value="1"
                      :unchecked-value="0"
                      @click="changeServiceEnable(record.id,record.enable)">
              <template #checkedChildren>
                <check-outlined/>
              </template>
              <template #unCheckedChildren>
                <close-outlined/>
              </template>
            </a-switch>
          </template>
          <template v-if="column.key === 'action'">
                                  <span>
                                    <a @click="changeServiceData(record)">编辑</a>
                                    <a-divider type="vertical"/>
                                    <a-popconfirm
                                        v-if="data.length"
                                        title="确认删除？"
                                        @confirm="onDeleteService(record.id)"
                                        ok-text="确认"
                                        cancel-text="取消"
                                    >
                                      <a>删除</a>
                                    </a-popconfirm>
                                  </span>
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </div>
</template>

<style scoped>

</style>
