<script setup>

import {SmileOutlined, UploadOutlined} from "@ant-design/icons-vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import {message} from "ant-design-vue";
import router from "@/router";
const {proxy} = getCurrentInstance()
const spinning = ref(false);

// 医院
// -----------------------------------------------------------------------------------
// 添加医院信息
const hospitalData = ref({"name": "", "level": "", "describe": ""})
const modalHospital = ref(false)
const hospitalPic = ref("")
const uploading = ref(false)
const modelProduct = ref()
const modalAction = ref("add")
const carouselFileList = ref([]);
const hospitalFileList = ref([]);
const handleHospitalRemove = file => {
  const index = hospitalFileList.value.indexOf(file);
  const newFileList = hospitalFileList.value.slice();
  newFileList.splice(index, 1);
  hospitalFileList.value = newFileList;
};
const beforeHospitalUpload = file => {
  hospitalFileList.value = [...(hospitalFileList.value || []), file];
  uploadHospitalPic();
  return false;
};
const uploadHospitalPic = () => {
  const formData = new FormData();
  hospitalFileList.value.forEach(file => {
    formData.append('files[]', file);
  });
  uploading.value = true;
  let file = hospitalFileList.value[0]  //file1是绑定的file对象
  let reader = new FileReader()
  let img = new Image()
  let aaa = ""
  // 读取图片
  reader.readAsDataURL(file)

  // 读取完毕后的操作
  reader.onloadend = (e) => {
    img.src = e.target.result
    // 这里的e.target就是reader
    // console.log(reader.result)
    // reader.result就是图片的base64字符串

    aaa = reader.result
    hospitalPic.value = aaa
  }

};
// 修改医院小程序可视
const changeHospitalEnable = async (id, enable) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    // 发送请求前的处理逻辑（如果有的话）
    // id -= 1;
    // console.log(enable);
    // console.log(data.value[id]);
    // enable = !enable;
    // console.log(enable);

    const response = await proxy.$api.post('/change_hospital_enable', {id: id, enable: enable});
    if (response.data.state === 'success') {
      modelProduct.value = {};
      // modalVisibleProduct.value = false;
    }
  } catch (error) {
    console.error(error);
    message.error('操作失败');
  } finally {
    hideMessage();
  }
};

// 修改医院信息
const changeHospitalData = (data) => {
  console.log(data)
  hospitalPic.value = data.image
  hospitalData.value = data
  modalHospital.value = true
  modalAction.value = 'update'

}
// 删除医院
const onDeleteHospital = async (id) => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    console.log(id);
    await proxy.$api.post('/delete_hospital', {id: id});
    message.error('删除成功');
    await getData()
  } catch (error) {
    console.error(error);
    message.error('删除失败');
  } finally {
    hideMessage()
  }
};

// 添加医院
const submitHospitalChange = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    let response;
    if (modalAction.value === 'update') {
      hospitalData.value['image'] = hospitalPic.value;
      response = await proxy.$api.post('/change_hospital', hospitalData.value);
    } else {
      const payload = {
        image: hospitalPic.value,
        name: hospitalData.value['name'],
        level: hospitalData.value['level'],
        hospital_describe: hospitalData.value['hospital_describe']
      };
      response = await proxy.$api.post('/add_hospital', payload);
    }

    carouselFileList.value = [];
    modalHospital.value = false;
    message.success(response.data.state);
    await getData()
    modalAction.value = 'add';
  } catch (error) {
    console.error(error);
    message.error('操作失败');
  } finally {
    hideMessage()
  }
};

// 医院表单
const columns_hospital = [
  {
    name: 'id',
    title: '医院id',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '医院图片',
    dataIndex: 'image',
    key: 'image',
  },
  {
    title: '医院名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '医院等级',
    dataIndex: 'level',
    key: 'level',
  },
  {
    title: '医院描述',
    dataIndex: 'hospital_describe',
    key: 'hospital_describe',
  },
  {
    title: '创建日期',
    dataIndex: 'create_date',
    key: 'create_date',
  },
  {
    title: '创建人',
    dataIndex: 'nickname',
    key: 'nickname',
  },
  {
    title: '启用',
    dataIndex: 'enable',
    key: 'enable',
  },
  {
    title: '操作',
    key: 'action',
  },
];
// -----------------------------------------------------------------------------------
const data = ref()
const loading = ref(false);
onMounted(async () => {
  loading.value = true; // 开始加载
  data.value = [];
  await getData()

});
const getData = async () => {
  const hideMessage = message.loading('加载中...', 0);  // 显示加载中提示，不会自动关闭

  try {
    const response = await proxy.$api.post('/get_hospitals');
    printResponse(response.data)
  } catch (error) {
    if (error.response.status === 401) {
      message.error("未授权")
    }
  }
  finally {
    hideMessage()
  }
};
// 改变data变量的值，显示表格数据
const printResponse = (responseData) => {
  if (responseData['status'] === 'error') {

    proxy.$cookies.set('loginCheck', 'false')
    // get_data()
    router.push({name: 'LoginView'})
  }
  data.value = responseData
  loading.value = false
}
</script>

<template>
  <div>
    <a-modal
        v-model:open="modalHospital"
        title="医院信息"
        centered
        :footer="null"
    >
      <a-spin :spinning="spinning" tip="加载中...">
        <div class="steps-content">
          <a-form :model="hospitalData" :label-col="{style: { width: '150px' }}"
                  :wrapper-col="{ span: 14 }">
            <a-image
                v-if="hospitalPic!==''"
                :width="100"
                :src="hospitalPic"
                style="margin-bottom: 10px"
            />
            <a-upload
                :file-list="hospitalFileList" :before-upload="beforeHospitalUpload"
                @remove="handleHospitalRemove"
                name="file"
                :max-count="1"
                style="display:block;margin-bottom: 20px"
                :show-upload-list="false"
            >
              <a-button>
                <upload-outlined></upload-outlined>
                医院图片
              </a-button>
            </a-upload>

            <a-form-item label="医院名称">
              <a-input v-model:value="hospitalData.name"/>
            </a-form-item>
            <a-form-item label="医院等级">
              <a-input v-model:value="hospitalData.level"/>
            </a-form-item>
            <a-form-item label="医院描述">
              <a-input v-model:value="hospitalData.hospital_describe"/>
            </a-form-item>
          </a-form>
          <!--                {{ // steps[current].content }}-->
        </div>
        <div class="steps-action">
          <a-button
              type="primary"
              @click="submitHospitalChange"
          >
            提交
          </a-button>
        </div>
      </a-spin>
    </a-modal>
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item>医院列表</a-breadcrumb-item>
      <!--                    <a-breadcrumb-item>List</a-breadcrumb-item>-->
      <!--                    <a-breadcrumb-item>App</a-breadcrumb-item>-->
    </a-breadcrumb>
    <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '280px' }"
    >
      <div style="margin-bottom: 8px;display: flex">
        <a-button @click="modalHospital=!modalHospital">
          添加医院
        </a-button>
        <!--                                <a-popconfirm-->
        <!--                                    title="确认删除？"-->
        <!--                                    @confirm="delectSelectTable"-->
        <!--                                    ok-text="确认"-->
        <!--                                    cancel-text="取消"-->
        <!--                                    style="display:block;margin-left: 20px"-->
        <!--                                >-->
        <!--                                    <a-button type="primary" danger style="display:block;margin-left: 20px">删除</a-button>-->
        <!--                                </a-popconfirm>-->
      </div>

      <a-table :row-selection="{ selectedRowKeys: tableSelectedRowKeys, onChange: onSelectChange }"
               :columns="columns_hospital" :data-source="data" :loading="loading"
               @change="handleTableChange">
        <template #headerCell="{ column }">
          <template v-if="column.key === 'id'">
                              <span>
                                <smile-outlined/>
                                医院id
                              </span>
          </template>
        </template>

        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'image'">
            <a-image
                :width="100"
                :src="record.image"
            />
          </template>
          <template v-else-if="column.key === 'enable'">
            <a-switch v-model:checked="record.enable"
                      :checked-value="1"
                      :unchecked-value="0"
                      @click="changeHospitalEnable(record.id,record.enable)">
              <template #checkedChildren>
                <check-outlined/>
              </template>
              <template #unCheckedChildren>
                <close-outlined/>
              </template>
            </a-switch>
          </template>
          <template v-else-if="column.key === 'action'">
                                  <span>
<!--                                    <a @click="setModalVisible(record)">展示</a>-->
                                    <a @click="changeHospitalData(record)">编辑</a>
                                    <a-divider type="vertical"/>
                                    <a-popconfirm
                                        v-if="data.length"
                                        title="确认删除？"
                                        @confirm="onDeleteHospital(record.id)"
                                        ok-text="确认"
                                        cancel-text="取消"
                                    >
                                      <a>删除</a>
                                    </a-popconfirm>
                                  </span>
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </div>
</template>

<style scoped>

</style>
